const { DateTime } = require("luxon");

function convert(dateTime, relative, options) {
    let now = DateTime.now().toUTC();

    if (typeof dateTime.getMonth === 'function') {
        if (relative) {
            let diff = now.diff(DateTime.fromISO(dateTime.toISOString()), "second");
            if (diff.seconds <= 1) {
                return "now";
            }
            return DateTime.fromISO(dateTime.toISOString()).toRelative();
        }
        return DateTime.fromISO(dateTime.toISOString()).toLocaleString(options);
    }

    // e.g. "20210424T19:35:45.96"
    if (!dateTime.includes("Z") && !dateTime.includes("+") && !dateTime.includes("-")) {
        dateTime += "Z";
    }
    // e.g. "2021-04-24T19:35:45.96"
    else if (!dateTime.includes("Z") && !dateTime.includes("+") && dateTime.includes("-") && dateTime.includes("T")) {
        dateTime += "Z";
    }

    if (relative) {
        let diff = now.diff(DateTime.fromISO(dateTime), "second");
        if (diff.seconds <= 1) {
            return "now";
        }
        return DateTime.fromISO(dateTime).toRelative();
    }
    return DateTime.fromISO(dateTime).toLocaleString(options);
}

export function getSecondsBetweenDates(startDate, endDate) {
    let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
    return (diff / (1000));
}

export function getMinutesBetweenDates(startDate, endDate) {
    let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
    return (diff / (1000 * 60));
}

export function getHoursBetweenDates(startDate, endDate) {
    let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
    return (diff / (1000 * 60 * 60));
}

export function getDaysBetweenDates(startDate, endDate) {
    let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
    return (diff / (1000 * 60 * 60 * 24));
}

// Example: July 25, 2021
export function convertToLocaleDateTimeString(dateTime, relative = true) {
    let convertedDate = convert(dateTime, relative, DateTime.DATE_FULL);
    return convertedDate;
}

// Example: Jul 25
export function convertToAbbreviatedMonthAndDay(dateTime, relative = true) {
    let convertedDate = convert(dateTime, relative, { month: 'short', day: 'numeric' });
    return convertedDate;
}   

// Example: Jul 25, 1:48 AM
export function convertToAbbreviatedMonthAndDayAndTime(dateTime) {
    let convertedDate = convert(dateTime, null, { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });
    return convertedDate;
}

// Example: Sun 1:48 AM
export function convertToNameOfWeekdayAndTime(dateTime) {
    let convertedDate = convert(dateTime, null, { weekday: 'short', hour: 'numeric', minute: 'numeric' });
    return convertedDate;
}

// Example: 7/25
export function convertToNumericMonthAndDay(dateTime, relative = true) {
    let convertedDate = convert(dateTime, relative, { month: 'numeric', day: 'numeric' });
    return convertedDate;
}

// Example: 7/25/2021
export function convertToNumericMonthAndDayAndYear(dateTime, relative = true) {
    let convertedDate = convert(dateTime, relative, { month: 'numeric', day: 'numeric', year: 'numeric',locale:"en-us" });
    return convertedDate;
}

// Example: 7/25/2021, 1:48 AM
export function convertToNumericMonthAndDayAndYearAndTime(dateTime, relative = true) {
    let convertedDate = convert(dateTime, relative, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
    return convertedDate;
}

export function asDate(dateTime) {
    return dateTime.minus({ millisecond: dateTime.millisecond, second: dateTime.second, minute: dateTime.minute, hour: dateTime.hour });
}