import React, { Component } from "react";
import _ from "lodash";
import LoadSpinner from "./LoadSpinner";
import ImageWrapper from "components/ImageWrapper";
import ActionButton from "components/ActionButton";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";
import ProductRowMobile from "views/Account/AnalyticsByProductRowMobile";
import NoResults from "components/NoResults";
import Modal from 'react-modal';


const { DateTime } = require("luxon");


class AnalyticsByProductTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            tab: 1,
            show: false,
            showSales: false,
            clicked: null,
        };
    }

    componentDidMount = async () => {
        await this.props.resetPerformanceTabs();
        await this.props.getAnalytics(this.props.startDate, this.props.endDate);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged) {
            await this.props.getAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display) {
            await this.props.getAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.browseChanged != null && prevProps.browseChanged !== this.props.browseChanged && this.props.display) {
            await this.props.getAnalytics(this.props.startDate, this.props.endDate);
        }
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    showSalesModal = (e) => {
        e.preventDefault();
        this.setState({ showSales: !this.state.showSales });
    };

    hideModal = () => {
        this.setState({ show: false, clicked: null });
        this.props.clearPerformance();
        this.props.clearSourceSort();
    }

    hideSalesModal = () => {
        this.setState({ showSales: false, salesClicked: null });
        this.props.clearPerformance();
        this.props.clearSalesSourceSort();
    }

    displayProductsMobile = () => {
        if (this.props.analytics?.length === 0) {
            return (
                <div className="mt-3">
                    <NoResults />
                </div>
            )
        }

        const productData = {
            imageUrl: "",
            name: "",
            price: "",
            sale: "",
            salePrice: "",
            integrated: "",
            merchant: "",
            clicks: "clicks",
            sales: "sales",
            conversionRate: "conversions"
        };

        if (this.props.analytics?.length > 0) {
            const analytics = [productData, ...this.props.analytics];

            return analytics?.map((product, i) => {
                return (
                    <div key={i}>
                        <ProductRowMobile
                            handleGetLink={this.props.handleGetLink}
                            product={product}
                            productKey={i}
                            key={i}
                            edit={() => { }}
                            hide={() => { }}
                            toggleProductModal={() => { }}
                            toggleShareModal={() => { }}
                            isUserMerchant={false}
                            editClick={() => { }}
                            hideClick={() => { }}
                            deviceSize={this.props.deviceSize}
                            handleSortSelect={this.props.handleSortSelect}
                            sortDescending={this.props.sortDescending}
                            selectOptions={this.props.selectOptions}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            getAnalyticsPerformanceSourceByUserMerchantProduct={this.props.getAnalyticsPerformanceSourceByUserMerchantProduct}
                            getAnalyticsPerformanceSourceByLink={this.props.getAnalyticsPerformanceSourceByLink}
                            handleClicksClick={this.handleClicksClick}
                            handleSalesClicksClick={this.handleSalesClicksClick}
                            handleLinkSalesClicksClick={this.handleLinkSalesClicksClick}
                            loggedInUser={this.props.loggedInUser}
                        />
                    </div>
                )

            })
        }
        return <></>;
    }

    displayTableHeader = () => {
        let headers = ["Link", "Brand", "Price", "Clicks", "Sales Count", "Sales", "Commissions", "Conv."];

        return headers.map((header, i) => {
            const options = [
                { label: "Name", value: 1 },
                { label: "Clicks", value: 2 },
                { label: "Sales", value: 3 },
                { label: "Conversion Rate", value: 4 },
                { label: "Merchant", value: 5 },
                { label: "Price", value: 6 },
                { label: "Sale Amt", value: 7 },
                { label: "Commission", value: 8 },
            ]

            if (header === "Link") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[0])} style={{ verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Name" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Clicks") {
                return (
                    <th key={i} className="d-flex" onClick={() => this.props.handleSortSelect(options[1])} style={{ padding: 0, verticalAlign: "baseline", lineHeight: "inherit", paddingLeft: "7px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.state.tab === 1 && this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Clicks" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Sales Count") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[2])} style={{ position: "relative", left: "10px", padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", padding: 0 }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Sales" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Conv.") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[3])} style={{ textAlign: "center", padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Conversion Rate" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Brand") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[4])} style={{ position: "relative", left: "10px", padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Merchant"? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Price") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[5])} style={{ position: "relative", left: "10px", padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Price" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Sales") {
                return (
                    <th key={i} onClick={() => this.props.handleSortSelect(options[6])} style={{ position: "relative", left: "6px", textAlign: "center", maxWidth: "50px",padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Sale Amt" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Commissions") {
                return (
                    <th key={i} className="d-flex" onClick={() => this.props.handleSortSelect(options[7])} style={{ position: "relative", left: "10px", padding: 0, verticalAlign: "baseline", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sortDescending ? "-4px" : "9px", left: this.props.sortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.selectOptions.currentValue.label === "Commission" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else {
                return (
                    <th key={i} style={{ padding: 0, verticalAlign: "baseline", paddingLeft: "12px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", border: "none" }}>
                        <strong>{header}</strong>
                    </th>
                );
             }
        });
    };

    handleClicksClick = (e, product, clicksZero) => {
        if (clicksZero) {
            return;
        }
        e.stopPropagation();
        if (!!(product.logoUrl)) {
            this.props.getAnalyticsPerformanceSourceByLink(this.props.startDate, this.props.endDate, product.referralUrl);
        } else {
            this.props.getAnalyticsPerformanceSourceByUserMerchantProduct(this.props.startDate, this.props.endDate, product.userMerchantProductId ?? product.merchantLinkId);
        }
        this.setState({ clicked: product });
        this.showModal(e);
    }

    handleSalesClicksClick = (e, product, salesZero) => {
        if (salesZero || !product.sourceDataAvailable) {
            return;
        }
        e.stopPropagation();
        if (!!(product.logoUrl)) {
            this.props.getAnalyticsPerformanceSalesSourceByLink(this.props.startDate, this.props.endDate, product.referralUrl);
        } else {
            this.props.getAnalyticsPerformanceSalesSourceByUserMerchantProduct(this.props.startDate, this.props.endDate, product.userMerchantProductId);
        }
        this.setState({ salesClicked: product });
        this.showSalesModal(e);
    }


    displayTableRows = () => {
        if (this.props.analytics?.length > 0) {
            return this.props.analytics.map((product, i) => {
                let getLinkUrl = "https://";
                if (window.location.host.includes("app.rockp") && !!product.shareSubdomain) {
                    getLinkUrl += product.shareSubdomain + ".rockporch.com";
                } else {
                    getLinkUrl += window.location.host;
                }

                if (!!(product.logoUrl)) {
                    getLinkUrl = getLinkUrl + "/mlink/" + product.merchantId + "?userId=" + this.props.loggedInUser?.id + "&redirectUrl=" + encodeURIComponent(product.referralUrl);
                } else {
                    getLinkUrl = getLinkUrl + "/prdlink/" + product.shareCode;
                }

                const url = "/Product/ProductPage/" + product.shareCode;

                const clicksZero = product.clicks === 0;
                const salesZero = product.sales < 1;

                return (
                    <tr key={i} className="hover-background"
                        onMouseEnter={() => this.setState({ hoverRow: i })}
                        onMouseLeave={() => this.setState({ hoverRow: -2 })}
                        style={{ cursor: "pointer", borderBottom: this.state.hoverRow === i + 1 ? "1px solid gray" : "" }}
                        onClick={() => this.props.handleGetLink(getLinkUrl)}
                    >
                        <td style={{ background: "white", maxWidth: "250px", wordWrap: "break-word", border: "none" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ImageWrapper src={product.imageUrl ?? product.logoUrl} style={{ objectFit: "contain" }} />
                                <h6 class={`${!!(product.logoUrl) ? "one-line-ellipsis" : ""}`} style={{ wordBreak: "break-all" }}>{!!(product.name) ? titleCase(product.name) : !!(product.referralUrl) ? product.referralUrl : ""}</h6>
                            </div>
                        </td>

                        <td style={{ maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span>{product.merchant}</span>
                        </td>

                        <td style={{ maxWidth: "150px", wordWrap: "break-word", border: "none" }}>
                            <span>{!!(product.price) ? currencyFormat(product.price) : ""}</span>
                        </td>

                        <td className="pr-4 brand-primary-color" onClick={(e) => this.handleClicksClick(e, product, clicksZero) } style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span className={`${clicksZero ? "" : "brand-primary-color"}`}>{product.clicks}</span>
                        </td>

                        <td className="" onClick={(e) => this.handleSalesClicksClick(e, product, salesZero)} style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span className={`${salesZero ? "" : "brand-primary-color"}`}>{!product.sourceDataAvailable ? "N/A*" : product.sales}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${product.salesAmount.toFixed(2)}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${product.commission.toFixed(2)}</span>
                        </td>

                        <td className="pr-4" style={{ maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span>{product.conversionRate}%</span>
                        </td>
                    </tr>
                )
            })
        }
    }

    handleSourceClicksSort = async () => {
        await this.props.handleSourceReferrerClick(false, this.props.sourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSalesSourceClicksSort = async () => {
        await this.props.handleSalesSourceReferrerClick(false, this.props.salesSourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSourceReferrerSort = async () => {
        await this.props.handleSourceReferrerClick(true, this.props.sourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSalesSourceReferrerSort = async () => {
        await this.props.handleSalesSourceReferrerClick(true, this.props.salesSourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    displayProductClicksTable = () => {
        if (this.props.performanceSourceUserMerchantProduct?.length > 0) {
            return (
                <div className="product-table mx-auto mb-3 post-edit-products-scroll" style={{ width: "90%", maxHeight: "300px" }}>
                    <table key={-1} className="table" style={{ border: 0, margin: "0 auto" }}>
                        <colgroup>
                            <col span="1" style={{ width: "75%" }} />
                            <col span="1" style={{ width: "35%" }} />

                        </colgroup>
                        <tbody>
                            <tr className="pb-3">
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSourceReferrerSort } style={{ cursor: "pointer" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }} >Source</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sourceSortAscending ? "16px" : "29px", left: this.props.sourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${this.props.sourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSourceClicksSort} style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>Clicks</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sourceSortAscending ? "16px" : "29px", left: this.props.sourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${!this.props.sourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {this.displayProductsClickRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayProductSalesClicksTable = () => {
        if (this.props.performanceSalesSourceUserMerchantProduct?.length > 0) {
            return (
                <div className="product-table mx-auto mb-3 post-edit-products-scroll" style={{ width: "90%", maxHeight: "300px" }}>
                    <table key={-1} className="table" style={{ border: 0, margin: "0 auto" }}>
                        <colgroup>
                            <col span="1" style={{ width: "75%" }} />
                            <col span="1" style={{ width: "35%" }} />

                        </colgroup>
                        <tbody>
                            <tr className="pb-3">
                                <td style={{ border: 0, padding: 0 }}>
                                    <div style={{ cursor: "pointer" }}>
                                        <h6 onClick={this.handleSalesSourceReferrerSort} style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }} >Source</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.salesSourceSortAscending ? "16px" : "29px", left: this.props.salesSourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.salesSourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${this.props.salesSourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSalesSourceClicksSort} style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>Clicks</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.salesSourceSortAscending ? "16px" : "29px", left: this.props.salesSourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.salesSourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${!this.props.salesSourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {this.displayProductsSalesClickRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayProductsClickRows = () => {
        return this.props.performanceSourceUserMerchantProduct.map((p, i) => {
            return (
                <>
                    <tr key={i} className="py-3" style={{ borderBottom: this.props.performanceSourceUserMerchantProduct.length - 1 === i ? "" : "1px solid lightgray" }}>
                        <td className="pl-1" style={{ border: 0, wordBreak: "break-all", marginRight: "10px", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{p.referrer?.trim().length > 0 ? p.referrer : "direct / not provided"}</span>
                        </td>
                        <td style={{ border: 0, wordBreak: "break-all", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{p.count}</span>
                        </td>
                    </tr>
                </>
            )
        });
    }

    displayProductsSalesClickRows = () => {
        return this.props.performanceSalesSourceUserMerchantProduct.map((p, i) => {
            return (
                <>
                    <tr key={i} className="py-3" style={{ borderBottom: this.props.performanceSalesSourceUserMerchantProduct.length - 1 === i ? "" : "1px solid lightgray" }}>
                        <td className="pl-1" style={{ border: 0, wordBreak: "break-all", marginRight: "10px", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{p.referrer?.trim().length > 0 ? p.referrer : "direct / not provided"}</span>
                        </td>
                        <td style={{ border: 0, wordBreak: "break-all", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{p.count}</span>
                        </td>
                    </tr>
                </>
            )
        });
    }

    render() {
        return (
            <>
                {
                    this.props.analyticsLoading ?
                        <LoadSpinner /> :
                        <section>
                            <Modal isOpen={this.state.show}
                                className="post-edit-products-scroll"
                                style={
                                    {
                                        overlay: {
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9998,
                                        },
                                        content: {
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            background: 'white',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            outline: 'none',
                                            padding: '20px',
                                            width: ["sm","md"].includes(this.props.deviceSize) ? '350px' : '500px',
                                            height: '500px',
                                            zIndex: 1060,
                                            borderRadius: ".3rem",
                                        }
                                    }
                                }
                                onRequestClose={this.hideModal}
                            >

                                <div className="d-flex flex-column h-100 bg-white">
                                    <div className="mx-0 my-0">
                                        <div className="d-flex flex-column pt-1">
                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                <ImageWrapper src={this.state.clicked?.imageUrl ?? this.state.clicked?.logoUrl} style={{ objectFit: "contain", width: "50px", height: "50px", border: "1px solid gray", borderRadius: "50%", }} />
                                                <h6 style={{ color: "black", marginBottom: 0, width: "80%" }}>{titleCase(this.state.clicked?.name ?? "Product")}</h6>
                                            </div>
                                            {
                                                this.props.analyticsPerformanceSourceByUserMerchantProductLoading ?
                                                    <LoadSpinner /> :
                                                    <>
                                                        {this.displayProductClicksTable()}
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal isOpen={this.state.showSales}
                                className="post-edit-products-scroll"
                                style={
                                    {
                                        overlay: {
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9998,
                                        },
                                        content: {
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            background: 'white',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            outline: 'none',
                                            padding: '20px',
                                            width: ["sm", "md"].includes(this.props.deviceSize) ? '350px' : '500px',
                                            height: '500px',
                                            zIndex: 1060,
                                            borderRadius: ".3rem",
                                        }
                                    }
                                }
                                onRequestClose={this.hideSalesModal}
                            >

                                <div className="d-flex flex-column h-100 bg-white">
                                    <div className="mx-0 my-0">
                                        <div className="d-flex flex-column pt-1">
                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                <ImageWrapper src={this.state.salesClicked?.imageUrl ?? this.state.salesClicked?.logoUrl} style={{ objectFit: "contain", width: "50px", height: "50px", border: "1px solid gray", borderRadius: "50%", }} />
                                                <h6 style={{ color: "black", marginBottom: 0, width: "80%", lineBreak: "anywhere" }}>{titleCase(this.state.salesClicked?.name ?? this.state.salesClicked?.referralUrl ?? "Product")}</h6>
                                            </div>
                                            {
                                                this.props.analyticsPerformanceSalesSourceByUserMerchantProductLoading ? 
                                                    <LoadSpinner /> :
                                                    <>
                                                        {this.displayProductSalesClicksTable()}
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Modal>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col" style={{ padding: "0" }}>
                                            
                                            {["sm", "md"].includes(this.props.deviceSize) ?
                                            this.displayProductsMobile()
                                            :
                                            this.props.analytics?.length === 0 ?
                                                <div className="mt-3">
                                                    <NoResults />
                                                </div>  
                                                :
                                                <div className="table-responsive product-table" style={{ overflowY: "auto" }}>
                                                        <table className="table" style={{ border: 0 }}>
                                                            <thead>
                                                                <tr>
                                                                    {this.displayTableHeader()}
                                                                </tr>
                                                            </thead>


                                                            <tbody>
                                                                {this.displayTableRows()}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                        </div>
                                    </div>
                                </div>
                            </section>
                }
            </>
        );
    }
}

export default AnalyticsByProductTable;