import React from "react";


const YoutubeIcon = (props) => {
    return (
        <svg width="24" height="24" style={props.hrefStyle} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4951 6.20522C23.356 5.70699 23.0908 5.25305 22.725 4.88727C22.3593 4.5215 21.9053 4.25628 21.4071 4.11723C19.5371 3.61623 12.0111 3.61623 12.0111 3.61623C12.0111 3.61623 4.5041 3.60623 2.6151 4.11723C2.11686 4.25628 1.66292 4.5215 1.29715 4.88727C0.931378 5.25305 0.66615 5.70699 0.527099 6.20522C0.172619 8.11974 -0.00214065 10.0632 0.0050988 12.0102C-0.000772064 13.9499 0.173982 15.8859 0.527099 17.7932C0.66615 18.2915 0.931378 18.7454 1.29715 19.1112C1.66292 19.4769 2.11686 19.7422 2.6151 19.8812C4.4831 20.3832 12.0111 20.3832 12.0111 20.3832C12.0111 20.3832 19.5171 20.3832 21.4071 19.8812C21.9053 19.7422 22.3593 19.4769 22.725 19.1112C23.0908 18.7454 23.356 18.2915 23.4951 17.7932C23.8408 15.8852 24.0082 13.9492 23.9951 12.0102C24.0096 10.0639 23.8422 8.12043 23.4951 6.20522ZM9.6091 15.6012V8.40823L15.8731 12.0102L9.6091 15.6012Z" fill="#ADB5BD" />
        </svg>
        )
}

export default YoutubeIcon;