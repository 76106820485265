import React, { Component } from "react";
import AccountSidebar from "../../components/AccountSidebar";
import AccountBanner from "../../components/AccountBanner";
import Modal from "../../components/Modal";
import CreatorApplication from "../../components/CreatorApplication";
import CreatorAppProfile from "../../components/CreatorAppProfile";
import CreatorDashboardContactUs from "../../views/CreatorDashboard/CreatorDashboardContactUs";
import { debounce } from "throttle-debounce";
import { getQueryStringParams } from "sharedUtils/navUtils"
import { NavLink } from "react-router-dom";
import ActionButton from "components/ActionButton";

import { getRequest } from "sharedUtils/httpUtils";

import { Form, FormGroup, Label, Input } from "reactstrap";
import { postRequest, postSpreadsheet } from "../../sharedUtils/httpUtils";
import { Helmet } from "react-helmet";
import { Select } from "@material-ui/core";
import { result } from "lodash";

class CreatorApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validUserName: true,
            usernameExists: false,
            validPassword: true,
            validEmail: true,
            toggleModal: false,
            approved: false,
            submitWithUnansweredRequiredFields: false,
            formData: {
                firstName: "",
                lastName: "",
                phone: "",
                whyRockporchToday: "",
                youtube: "",
                facebook: "",
                instagram: "",
                tikTok: "",
                blog: "",
                realName: "",
                audience: "",
                postFrequency: "",
                timeCommitment: "",
                platform: "",
                platformUsername: "",
                followers: "",
                additionalPlatforms: [{ id: "additionalPlatform", platform: "", userName: "", followers: "" }],
                affiliateMarketingExperience: "",
                mediaPermission: "yes",
                hearAboutUs: "",
                usState: "",
                learnMore: "",
                upcomingProjects: "",
                name: "",
                userName: "",
                email: "",
                confirmedEmail: "",
                phoneNumber: "",
                city: "",
                state: "",
                country: "",
                password: "",
                confirmedPassword: "",
                howWillUseRockporch: "",
                interested_in_product_seeding_campaigns: "",
            },
            agreeToTerms: false,
            savingProfile: false,
            modalOpen: false,
            postingExcel: false,
            excelError: false,
            uploadedFileNames: [],
            isSaving: false,
            isSavingCreatorSettings: false,
            userInformation: null,
            persistentUserInformation: null,
            basicUserInformation: null,
            basicDisplay: true,
            sectors: [],
            persistentSectors: [],
            allSectors: [],
            editMode: false,
            referralUserId: null,
            userIsCreator: false,
        };
    }

    highlightRequiredFields = () => {
        this.setState({ submitWithUnansweredRequiredFields: true });
    }

    onNewUserNameInputChange = async (e) => {
        e.preventDefault();
        this.setState({ usernameExists: false })
        let formKey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;

        formData[formKey] = newData;

        this.setState({ formData });

        let userNameRegex = /^[a-zA-Z0-9.\-_]{1,20}$/
        let validUserName = userNameRegex.test(formData.userName);

        if (validUserName) {
            this.debounceNewUserNameInputChange();
            this.setState({ validUserName: true })
        } else if (formData.userName.length > 0) {
            this.setState({ validUserName: false })
        } else {
            this.setState({ validUserName: true })
        }
    }

    checkPasswordCriteria = (password) => {

        const hasUpperCase = /[A-Z]{1,}/.test(password);
        const hasLowerCase = /[a-z]{1,}/.test(password);
        const hasDigit = /\d{1,}/.test(password);

        const validPassword = hasUpperCase && hasLowerCase && hasDigit && password.length > 7;
        this.setState({ validPassword });
    }

    checkEmailCriteria = (email) => {
        this.setState({ emailExists: false })

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let validEmail = emailRegex.test(email);

        if (validEmail) {
            this.debounceEmailInputChange();
            this.setState({ validEmail: true })
        } else if (email.length > 0) {
            this.setState({ validEmail: false })
        } else {
            this.setState({ validEmail: true })
        }
    }


    debounceNewUserNameInputChange = debounce(300, async () => {
        await this.checkUserNameExists();
    });

    debounceEmailInputChange = debounce(300, async () => {
        await this.checkEmailExists();
    });


    checkUserNameExists = async () => {
        if (!!(this.state.formData.userName)) {
            let url = `/api/Account/GetUserNameExists?username=${this.state.formData.userName}`;

            let usernameExists = await getRequest(url);

            this.setState({ usernameExists });
        }
    }

    checkEmailExists = async () => {
        let url = `/api/Account/CheckEmailAnonymous?email=${this.state.formData.email}`;

        let response = await getRequest(url);
        let emailExists = response && (!(this.props.loggedInUser) || this.state.formData.email !== this.props.loggedInUser.email)

        this.setState({ emailExists });
    }

    toggleModal = () => {
        this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));
    };

    getData = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        if (!!(this.props.loggedInUser)) {
            this.setState({ formData: this.convertToFormData(this.props.loggedInUser) });
        }
    };

    componentDidMount = async () => {
        if (!!(this.props.loggedInUser) && !!(this.props.loggedInUser.proStatusId) && this.props.loggedInUser.proStatusId === 1) {
            this.setState({ userIsCreator: true, modalOpen: true, approved: true });
        } else if (!!(this.props.loggedInUser)) {
            if (await this.userPendingCreatorApplicationExists()) {
                this.setState({ userIsCreator: true, modalOpen: true, approved: false });
            }
        }

        const link1 = document.createElement('link');
        link1.rel = 'preconnect';
        link1.href = 'https://fonts.googleapis.com';

        const link2 = document.createElement('link');
        link2.rel = 'preconnect';
        link2.href = 'https://fonts.gstatic.com';
        link2.crossOrigin = ''; // For crossorigin attribute

        const link3 = document.createElement('link');
        link3.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap';
        link3.rel = 'stylesheet';

        document.head.appendChild(link1);
        document.head.appendChild(link2);
        document.head.appendChild(link3);

        // Save the link elements in the component state for cleanup
        this.linkElements = [link1, link2, link3];

        const referralUserId = getQueryStringParams(window.location.search).ref;

        if (!isNaN(parseInt(referralUserId)) && parseInt(referralUserId) > 0) {
            this.setState({ referralUserId: parseInt(referralUserId) });
        }


        this.getData();

        this.updatePersistantUserInformation(this.props.loggedInUser);

        // preload splashPageBanner to avoid rendering delay
        const image = new Image();
        image.src = '/images/splashPageBanner-min.png';
        this.image = image;
    }

    componentWillUnmount() {
        this.linkElements.forEach((link) => {
            document.head.removeChild(link);
        });
        // unmount preloaded image
        if (this.image) {
            this.image.onload = null;
        }
    }

    userPendingCreatorApplicationExists = async () => {
        const applicationsForLoggedInUser = await this.fetchUserRoleApplicationsByUserId();

        if (applicationsForLoggedInUser?.length > 0) {
            // Sort applications by createdDate in descending order
            applicationsForLoggedInUser.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

            // Get the application with the latest createdDate
            const latestApplication = applicationsForLoggedInUser[0];

            // Check if latestApplication has the 'approved' property
            const hasApprovedProperty = latestApplication.hasOwnProperty('approved');

            // Return true if the 'approved' property does not exist. If it exists then it is either approved or unapproved.
            return !(hasApprovedProperty);
        }

        return false;
    }

    fetchUserRoleApplicationsByUserId = async () => {
        const url = "api/CreatorApplicationAdmin/GetUserApplications";
        const results = await getRequest(url);

        return results;
    }

    convertToFormData = (user) => {
        return {
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
            whyRockporchToday: "",
            name: user.name,
            userName: user.userName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            phone: user.phoneNumber,
            city: user.city,
            state: user.state,
            country: user.country,
            youtube: user.googleUsername,
            facebook: user.facebookUsername,
            instagram: user.instagramUsername,
            tikTok: user.tikTokUsername,
            blog: user.website,
            realName: user.name,
            audience: "",
            postFrequency: "",
            timeCommitment: "",
            platform: "",
            platformUsername: "",
            followers: "",
            additionalPlatforms: [{ id: "additionalPlatform", platform: "", userName: "", followers: "" }],
            affiliateMarketingExperience: "",
            mediaPermission: "yes",
            hearAboutUs: "",
            usState: "",
            learnMore: "",
            upcomingProjects: "",
            password: "",
            confirmedPassword: "",
            howWillUseRockporch: "",
        };
    }

    convertToUserModel = () => {
        const formData = this.state.formData;

        return {
            name: formData?.firstName.trim() + " " + formData.lastName.trim() ?? this.props.loggedInUser?.name ?? "",
            firstName: formData?.firstName ?? this.props.loggedInUser?.firstName ?? "",
            lastName: formData?.lastName ?? this.props.loggedInUser?.lastName ?? "",
            bio: formData?.bio ?? this.props.loggedInUser?.bio ?? null,
            tagLine: formData?.tagLine ?? this.props.loggedInUser?.tagLine ?? null,
            website: formData?.blog ?? this.props.loggedInUser?.website ?? null,
            googleUsername: formData?.youtube ?? this.props.loggedInUser?.googleUsername ?? null,
            facebookUsername: formData?.facebook ?? this.props.loggedInUser?.facebookUsername ?? "",
            instagramUsername: formData?.instagram ?? this.props.loggedInUser?.instagramUsername ?? null,
            tikTokUsername: formData?.tikTok ?? this.props.loggedInUser?.tikTokUsername ?? null,
            imageUrl: formData?.imageUrl ?? this.props.loggedInUser?.imageUrl ?? null,
            userName: formData?.userName ?? this.props.loggedInUser?.userName ?? "",
            email: formData?.email ?? this.props.loggedInUser?.email ?? "",
            phoneNumber: formData?.phoneNumber ?? this.props.loggedInUser?.phoneNumber ?? "",
            proStatusId: formData?.proStatusId ?? this.props.loggedInUser?.proStatusId ?? null,
            address: formData?.address ?? this.props.loggedInUser?.address ?? null,
            city: formData?.city ?? this.props.loggedInUser?.city ?? "",
            state: formData?.state ?? this.props.loggedInUser?.state ?? "",
            postalCode: formData?.postalCode ?? this.props.loggedInUser?.postalCode ?? null,
            country: formData?.country ?? this.props.loggedInUser?.country ?? "",
            onboardingComplete: formData?.onboardingComplete ?? this.props.loggedInUser?.onboardingComplete ?? null,
            profileBannerUrl: formData?.profileBannerUrl ?? this.props.loggedInUser?.profileBannerUrl ?? null,
            referralUserId: this.state.referralUserId,
        };
    }


    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === "password") {
            this.checkPasswordCriteria(e.target.value);
        }

        if (formKey === "email") {
            this.checkEmailCriteria(e.target.value);
        }

        this.setState(prevState => ({
            formData: { ...prevState.formData, [formKey]: newData }
        }));
    }; 

    onUserRelatedInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        const userInformation = this.state.userInformation;
        userInformation[formKey] = newData;

        this.setState({ userInformation });
        this.updateUserInformation(userInformation);
    };

    onInputAdditionalPlatformsChange = (e) => {
        const inputName = e.target.name;
        const [id, field] = inputName.split('-'); // Splitting the inputName into id and field
        const value = e.target.value;
        this.setState((prevState) => {
            // Find the index of the element in additionalPlatforms array
            const index = prevState.formData.additionalPlatforms.findIndex((item) => item.id === id);

            // Clone the existing state to avoid mutation
            const formData = { ...prevState.formData };

            if (index !== -1) {
                // Clone the specific object to update its property
                const updatedObject = { ...formData.additionalPlatforms[index] };

                // Update the field in the cloned object
                updatedObject[field] = value ?? "";

                // Update the specific element in the additionalPlatforms array
                formData.additionalPlatforms[index] = updatedObject;
            }

            return { formData };
        });
    };

    renderConfirmationModal = () => {
        if (this.state.userIsCreator) {
            return (
                <Modal modalDialogStyle={{ maxWidth: "unset", width: this.props.deviceSize === "sm" ? "" : "70%" }} isOpen={this.state.modalOpen} modalHeaderClass={`pt-0`} creatorApp={true} style={{ padding: 0 }} toggleModal={() => this.props.history.push(this.state.approved ? '/CreatorDashboard' : '/Explore')}>
                    <div class="position-relative">
                        <figure class="m-0 w-100">
                            <img src="/images/splashPageBanner-min.png" alt="" class="w-100 bg-image" style={{ objectFit: this.props.deviceSize === "sm" ? "cover" : "", height: this.props.deviceSize === "sm" ? "300px" : "" }} />
                        </figure>

                        <div style={{ width: "70%", position: "absolute", fontFamily: "Montserrat, sf_pro_displayregular, sans-serif", color: "white", top: ["sm"].includes(this.props.deviceSize) ? "35%" : "40%", left: "50%", transform: this.props.deviceSize === "sm" ? "translate(-50%, -15%)" : "translate(-50%, -25%)", textAlign: "center" }}>
                            {
                                this.state.approved ?
                                    <h1 style={{ fontWeight: 400, fontSize: this.props.screenWidth < 576 ? "22px" : this.props.screenWidth < 991 ? "28px" : "36px" }}>You are already an approved Creator!</h1>
                                        :
                                    <h1 style={{ fontWeight: 400, fontSize: this.props.screenWidth < 576 ? "22px" : this.props.screenWidth < 991 ? "28px" : "36px" }}>We are currently reviewing your Creator application</h1>
                            }
                            <h1 style={{ fontWeight: 400, fontSize: this.props.screenWidth < 576 ? "16px" : this.props.screenWidth < 991 ? "20px" : "24px" }}>Click here to go to your Creator Dashboard</h1>
                            <div className={`position-relative d-flex justify-content-center w-100 align-items-center mt-${["md", "sm"].includes(this.props.deviceSize) ? "4" : "5"}`}>
                                {
                                    this.state.approved ?
                                        <ActionButton onClick={() => this.props.history.push('/CreatorDashboard')} style={{ borderRadius: "3px", bottom: "5px", width: "292px" }} className="btn btn-primary">Creator Dashboard</ActionButton>
                                        :
                                        <ActionButton onClick={() => this.props.history.push('/Explore')} style={{ borderRadius: "3px", bottom: "5px", width: "292px" }} className="btn btn-primary">Explore</ActionButton>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
        return (
            <Modal modalDialogStyle={{ maxWidth: "unset", width: this.props.deviceSize === "sm" ? "" : "70%" }} isOpen={this.state.modalOpen} modalHeaderClass={`pt-0`} creatorApp={true} style={{ padding: 0 }} toggleModal={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                <div class="position-relative">
                    <figure class="m-0 w-100">
                        <img src="/images/splashPageBanner-min.png" alt="" class="w-100 bg-image" style={{ objectFit: this.props.deviceSize === "sm" ? "cover" : "", height: this.props.deviceSize === "sm" ? "300px" : "" }} />
                    </figure>            

                    <div style={{ width: "70%", position: "absolute", fontFamily: "Montserrat, sf_pro_displayregular, sans-serif", color: "white", top: ["sm"].includes(this.props.deviceSize) ? "35%" : "40%", left: "50%", transform: this.props.deviceSize === "sm" ? "translate(-50%, -15%)" : "translate(-50%, -25%)", textAlign: "center" }}>
                        <h1 style={{ fontWeight: 400, fontSize: this.props.screenWidth < 576 ? "22px" : this.props.screenWidth < 991 ? "28px" : "36px" }}>Thank you for your application</h1>
                        <h1 style={{ fontWeight: 400, fontSize: this.props.screenWidth < 576 ? "16px" : this.props.screenWidth < 991 ? "20px" : "24px" }}>Someone from the creator development team will be in touch regarding your application within 48 hours</h1>
                        <div className={`position-relative d-flex justify-content-center w-100 align-items-center mt-${["md","sm"].includes(this.props.deviceSize) ? "4" : "5"}`}>
                            <ActionButton onClick={() => this.setState({ modalOpen: false })} style={{ borderRadius: "3px", bottom: "5px", width: "292px" }} className="btn btn-primary">Explore RockPorch</ActionButton>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }


    onInputChangePhone = (input) => {
        let formKey = "phoneNumber";
        let newData = !!(input) ? input : "";

        this.setState(prevState => ({
            formData: { ...prevState.formData, [formKey]: newData }
        }));
    }

    isAdditionalPlatformAddable = () => {
        const tempObject = this.state.formData.additionalPlatforms.find(item => item.id === "additionalPlatform");

        if (tempObject) {
            return !!(tempObject.platform) && !!(tempObject.userName) && !!(tempObject.followers);
        } else {
            return false;
        }
    };


    addAdditionalPlatform = () => {
        if (this.isAdditionalPlatformAddable()) {
            const newPlatformWithId = { ...this.state.formData.additionalPlatforms.find(item => item.id === "additionalPlatform") };
            newPlatformWithId.id = newPlatformWithId.platform + "%" + newPlatformWithId.userName;

            let additionalPlatform = { id: "additionalPlatform", platform: "", userName: "", followers: "" };
            let additionalPlatforms = [...this.state.formData.additionalPlatforms.filter(item => item.id !== "additionalPlatform"), newPlatformWithId, additionalPlatform];
            let formData = { ...this.state.formData, additionalPlatforms };
            this.setState({ formData });
        }
    }

    deleteAdditionalPlatform = (id) => {
        let additionalPlatforms = [...this.state.formData.additionalPlatforms];

        additionalPlatforms = additionalPlatforms.filter(item => item.id !== id);
        if (id === "additionalPlatform") {
            let additionalPlatform = { id: "additionalPlatform", platform: "", username: "", followers: "" };
            additionalPlatforms = [...additionalPlatforms, ...additionalPlatform];
        }

        let formData = { ...this.state.formData, additionalPlatforms };
        this.setState({ formData });
    }

    waitForModalClose = () => {
        return new Promise(resolve => {
            const checkModalOpen = () => {
                if (!this.state.modalOpen) {
                    resolve();
                } else {
                    setTimeout(checkModalOpen, 100); // Adjust the delay as needed
                }
            };

            checkModalOpen();
        });
    }

    apply = async () => {
        this.setState({ isSaving: true });

        if (!!(this.props.loggedInUser)) {
            await this.saveUser();
        } else {
            await this.createNewUser();
        }

        let selectedSectorIds = this.state.sectors.map((sector) => { return sector.sectorId });
        let selectedSectorsNames = this.state.sectors.map(sector => sector.name).join(',');

        await postRequest('/api/Sector/UpdateUserSectors', selectedSectorIds);

        let formData = this.state.formData;
        if (formData.hearAboutUs === "referred") {
            formData.hearAboutUs = "";
        }
        formData.realName = this.state.formData.firstName.trim() + " " + this.state.formData.lastName.trim();
        formData.usState = this.state.formData.state;
        formData.phone = this.state.formData.phoneNumber;
        formData.password = "";
        formData.confirmedPassword = "";
        formData.sectors = selectedSectorsNames.length > 0 ? selectedSectorsNames : null;

        let additionalPlatforms = this.state.formData.additionalPlatforms.filter(item => item.platform.length > 0 && item.userName.length > 0);
        formData.additionalPlatforms = additionalPlatforms;

        let userUrl = '/api/Account/ApplyForPro';
        await postRequest(userUrl, formData);

        this.setState({ isSaving: false, modalOpen: true });

        await this.waitForModalClose();

        this.props.history.push('/Nav/Explore');
    }

    handleAgreeToTerms = () => {
        this.setState({ agreeToTerms: !this.state.agreeToTerms });
    }

    createNewUser = async () => {
        const userModel = this.convertToUserModel();
        userModel.password = this.state.formData.password;
        let userUrl = '/api/Account/CreateNewUser';

        let user = await postRequest(userUrl, userModel);
        this.props.setLoggedInUser(user);
    };

    saveUser = async () => {
        const userModel = this.convertToUserModel();

        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, userModel);

        //let url = `api/CreatorApplicationAdmin/SendCreatorAppSubmissionEmail`;
        //await postRequest(url, userModel);

        this.props.setLoggedInUser(user);
    };

    handleUpload = async (file, description) => {
        this.setState({ excelError: false, postingExcel: true });

        const formData = new FormData();
        formData.append("file", file);

        const response = await postSpreadsheet("/api/Account/UploadCreatorAppAttachment", formData);
        if (!!response && !!response.url) {
            // Append the new attachmentUrl to the existing value in the state
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    upcomingProjects: prevState.formData.upcomingProjects
                        ? prevState.formData.upcomingProjects + "," + description + "!@#" + response.url
                        : description + "!@#" + response.url,
                },
            }));
        }

        this.setState({ excelError: !(response), postingExcel: false });
    };

    parseUserInfo = (propsUserInformation) => {
        if (!!(propsUserInformation)) {
            return {
                name: propsUserInformation.name ?? this.state.persistentUserInformation?.name ?? "",
                email: propsUserInformation.email ?? this.state.persistentUserInformation?.email ?? "",
                address: propsUserInformation.address ?? this.state.persistentUserInformation?.address ?? "",
                city: propsUserInformation.city ?? this.state.persistentUserInformation?.city ?? "",
                state: propsUserInformation.state ?? this.state.persistentUserInformation?.state ?? "",
                country: propsUserInformation.country ?? this.state.persistentUserInformation?.country ?? "",
                website: propsUserInformation.website ?? this.state.persistentUserInformation?.website ?? "",
                postalCode: propsUserInformation.postalCode ?? this.state.persistentUserInformation?.postalCode ?? "",
                phoneNumber: propsUserInformation.phoneNumber ?? this.state.persistentUserInformation?.phoneNumber ?? "",
                tagLine: propsUserInformation.tagLine ?? this.state.persistentUserInformation?.tagLine ?? "",
                bio: propsUserInformation.bio ?? this.state.persistentUserInformation?.bio ?? "",
                userName: propsUserInformation.userName ?? this.state.persistentUserInformation?.userName ?? "",
                imageUrl: propsUserInformation.imageUrl ?? this.state.persistentUserInformation?.imageUrl ?? "",

                proStatusId: propsUserInformation.proStatusId ?? this.state.persistentUserInformation?.proStatusId ?? null,

                googleUsername: propsUserInformation.googleUsername ?? this.state.persistentUserInformation?.googleUsername ?? "",
                facebookUsername: propsUserInformation.facebookUsername ?? this.state.persistentUserInformation?.facebookUsername ?? "",
                instagramUsername: propsUserInformation.instagramUsername ?? this.state.persistentUserInformation?.instagramUsername ?? "",
                tikTokUsername: propsUserInformation.tikTokUsername ?? this.state.persistentUserInformation?.tikTokUsername ?? "",
                stravaUsername: propsUserInformation.stravaUsername ?? this.state.persistentUserInformation?.stravaUsername ?? "",

                onboardingComplete: propsUserInformation.onboardingComplete ?? this.state.persistentUserInformation?.onboardingComplete ?? null,
                profileBannerUrl: propsUserInformation.profileBannerUrl ?? this.state.persistentUserInformation?.profileBanner ?? "",

                quickStart: propsUserInformation.quickStart ?? this.state.persistentUserInformation?.quickStart ?? null,
                rpManagerUserId: propsUserInformation.rpManagerUserId ?? this.state.persistentUserInformation?.rpManagerUserId ?? null
            }
        }
    }

    setSectors = (sectors, allSectors) => {
        this.setState({ sectors, allSectors, persistentSectors: sectors });
    }

    updateSectors = (sectors) => {
        this.setState({ sectors })
    }

    updateBasicUserInformation = (propsUserInformation) => {
        this.setState({ userI: propsUserInformation });
    }

    updatePersistantUserInformation = (propsUserInformation) => {
        this.setState({ persistentUserInformation: this.parseUserInfo(propsUserInformation) });
    }

    mapInputFields = (fields) => {
        let readOnlyFields = ["profileUrl"];

        return fields.map((field, i) => {
            if (readOnlyFields.includes(field.name)) {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => this.onInputChange(e)}
                            value={this.state.formData[field.name]}
                            readonly="readonly"
                        />
                    </FormGroup>
                );
            } else {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => this.onInputChange(e)}
                            value={this.state.formData[field.name]}
                        />
                    </FormGroup>
                );
            }
        });
    }

    renderModal = () => {
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleModal}>
                <CreatorApplication
                    history={this.props.history}
                    isOpen={this.state.modalOpen}
                    loggedInUser={this.props.loggedInUser}
                    toggleModal={this.toggleModal}
                    onInputChange={this.onInputChange}
                    formData={this.state.formData}
                    saveUser={this.saveUser}
                />
            </Modal>
        )
    }
    
    render() {
        return (
            <section>
                <Helmet>
                    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/44855011.js"></script>
                </Helmet>
                <div class="position-relative">
                    <figure class="m-0 w-100">
                        <img src="/images/top-banner.png" alt="" className="w-100 bg-image" style={{ height: "250px" }} />
                    </figure>
                    <a href="https://www.rockporch.com" rel="noreferrer" target="_blank" style={{ cursor: "pointer", position: "absolute", top: "20px", left: "3%" }}>
                        <img src={"/images/rockporch-logo-white.svg"} alt="" />
                    </a>
                    <div style={{ position: "absolute", fontFamily: "Montserrat, sf_pro_displayregular, sans-serif", color: "white", top: this.props.deviceSize === "sm" ? "38%" : "44%", left: "50%", transform: this.props.deviceSize === "sm"  ? "translate(-50%, -15%)" :"translate(-50%, -25%)", textAlign: "center", width: "80%" }}>
                        <h1 style={{ fontWeight: 700, fontSize: this.props.deviceSize === "sm" ? "1.7rem" : "2.3rem" }}>WHERE PASSION MEETS PURPOSE</h1>
                        <h1 style={{ fontWeight: 400, fontSize: this.props.deviceSize === "sm" ? "1.2rem" : "1.7rem" }}>Join Your Fellow Passion-Driven Creators in the RockPorch Creator Community</h1>
                    </div>
                </div>


                <div className="full-height">
                    {this.renderConfirmationModal()}
                    <div className="container">
                        <div className="py-5 mt-0" style={{ borderBottom: "1px solid lightgray" }}>

                            <div className="">
                                <p style={{ fontSize: "12px", color: this.state.submitWithUnansweredRequiredFields ? "red" : "" }}><span style={{ position: "relative", top:"-3px" }}>*</span> Required field</p>
                                {/*<CreatorApplication*/}
                                {/*    handleUpload={this.handleUpload}*/}
                                {/*    error={this.state.excelError}*/}
                                {/*    isLoading={this.state.postingExcel}*/}
                                {/*    history={this.props.history}*/}
                                {/*    creatorApp={true}*/}
                                {/*    loggedInUser={this.props.loggedInUser}*/}
                                {/*    toggleModal={this.toggleModal}*/}
                                {/*    isOpen={this.state.modalOpen}*/}
                                {/*    paddingTop={"pt-5"}*/}
                                {/*    paddingBottom={"pb-4"}*/}
                                {/*    onInputChange={this.onInputChange}*/}
                                {/*    formData={this.state.formData}*/}
                                {/*    saveUser={this.saveUser}*/}
                                {/*    onInputChangePhone={this.onInputChangePhone}*/}
                                {/*/>*/}
                                <CreatorAppProfile
                                    submitWithUnansweredRequiredFields={this.state.submitWithUnansweredRequiredFields}
                                    highlightRequiredFields={this.highlightRequiredFields}
                                    validPassword={this.state.validPassword}
                                    usernameExists={this.state.usernameExists}
                                    validUserName={this.state.validUserName}
                                    emailExists={this.state.emailExists}
                                    validEmail={this.state.validEmail}
                                    onNewUserNameInputChange={this.onNewUserNameInputChange}
                                    handleAgreeToTerms={this.handleAgreeToTerms}
                                    agreeToTerms={this.state.agreeToTerms}
                                    onInputAdditionalPlatformsChange={this.onInputAdditionalPlatformsChange}
                                    addAdditionalPlatform={this.addAdditionalPlatform}
                                    deleteAdditionalPlatform={this.deleteAdditionalPlatform}
                                    isAdditionalPlatformAddable={this.isAdditionalPlatformAddable}
                                    userInformation={this.props.loggedInUser}
                                    deviceSize={this.props.deviceSize}
                                    isSaving={this.state.isSaving}
                                    updateUserInformation={this.updateBasicUserInformation}
                                    cancelEdit={this.state.cancelEdit}
                                    resetCancelEdit={this.resetCancelEdit}
                                    updatePersistantUserInformation={this.updatePersistantUserInformation}
                                    persistentUserInformation={this.state.persistentUserInformation}
                                    persistentSectors={this.state.persistentSectors}
                                    setSectors={this.setSectors}
                                    updateSectors={this.updateSectors}
                                    hideDisplay={["sm"].includes(this.props.deviceSize) && !this.state.basicDisplay}
                                    formData={this.state.formData}
                                    onInputChange={this.onInputChange}
                                    onInputChangePhone={this.onInputChangePhone}
                                    apply={this.apply}
                                    loggedInUser={this.props.loggedInUser}
                                />
                            </div>
                        </div>
                        <div className="mt-5" style={{ marginLeft: ["sm","md"].includes(this.props.deviceSize) ? "12px" : "" }}>
                            <CreatorDashboardContactUs deviceSize={this.props.deviceSize} creatorApp={true} />
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default CreatorApp;
