import React from "react";
import parse from "html-react-parser";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import ProfileInfoCard from "components/ProfileInfoCard";
import PlusIcon from 'components/icons/PlusIcon';
import GridIcon from 'components/icons/GridIcon';
import CommentForm from 'components/CommentForm';
import CommentTile from 'components/CommentTile';
import Modal from "components/Modal";
import ImageWrapper from "components/ImageWrapper";
import LoadSpinner from "components/LoadSpinner";
import ProductsSelectorModal from "components/UserMerchantProducts/ProductsSelectorModal";
import PostPageProduct from "components/PostPageProduct";
import UserTileGeneral from "components/UserTileGeneral";

import BookmarkIcon from "components/icons/BookmarkIcon";
import ShareIosIcon from "components/icons/ShareIosIcon";
import SaleIcon from "components/icons/SaleIcon";

import DeleteProductConfirm from "views/Locker/DeleteProductConfirm";
import EditProductCommentForm from "views/Locker/EditProductCommentForm";

import { getRequest, postRequest } from "sharedUtils/httpUtils"
import blankFeedPost from "sharedUtils/blankFeedPost";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";
import { toggleProductSelectModal, toggleProductPageModal, toggleFollowersFollowingModal, toggleShareModal, closeAllModals } from "stateManagement/reducers/modalReducer";
import { convertToLocaleDateTimeString } from "sharedUtils/timeUtils"
import { getLockerShareLink } from "../../sharedUtils/shareLinkPrefixes"
import InformationIcon from "../../components/icons/InformationIcon";
import ThumbsUpIcon from "../../components/icons/ThumbsUpGeneric";
import { Helmet } from 'react-helmet';


class LockerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locker: blankFeedPost,
            comment: "",
            showModal: false,
            loggedInUser: {},
            isLoading: true,
            loadingComments: false,
            isEditingProducts: false,
            deletingProduct: {
                isDeleting: false,
                coupon: ""
            },
            showDescriptionModal: false,
            productPage: 0,
            submittingComment: false,
            shareLink: "",
            savedByUser: false,
            collapsed: true,
            contentStyle: this.props?.deviceSize === "sm" ? this.notOverflowedContentStyle : this.notOverflowedContentStyle,
            contentOverflowed: false,
            maxHeight: "41px",
        };
    }

    commentsTextArea = React.createRef();
    contentRef = React.createRef();
    bioRef = React.createRef();
    titleRef = React.createRef();

    collapsedContentStyle = { textAlign: "justify", marginTop: "0em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden" };
    notOverflowedContentStyle = { textAlign: "justify", marginTop: "0em", marginBottom: "0.5em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden" };
    expandedContentStyle = { textAlign: "justify", marginTop: "0em" };

    async componentDidMount() {
        if (this.props.setShowTopNav) {
            this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
        }

        let shareCode = "";
        let post = null;
        let loggedInUser = null;

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        if (this.props.isModal !== true) {
            window.scrollTo(scrollOptions);
            this.props.closeAllModals();
        }

        // from feed
        if (this.props.match.params != undefined && this.props.match.params.post != undefined) {
            shareCode = this.props.match.params.post.shareCode;
            post = this.props.match.params.post;
            loggedInUser = this.props.match.params.loggedInUser;
            // from modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params != undefined &&
            this.props.location.state.destinationState.params.post != undefined) {

            shareCode = this.props.location.state.destinationState.params.post.shareCode;
            post = { ...this.props.location.state.destinationState.params.post, comments: [] };
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;

            // from direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        let shareLink = await getLockerShareLink(shareCode);

        if (loggedInUser == undefined) {
            let data = await this.getPageData(shareCode, false);
            if (data == undefined || data == null) {
                this.props.history.push("/NotFound");
                //window.location.href = window.location.origin + "/NotFound";
                //this.setState({ post: "notFound", isLoading: false, shareCode, shareLink })
            } else {
                this.setState({ locker: data, isLoading: false, shareCode, shareLink });
            }
        } else {
            if (post == undefined) {
                let data = await this.getPageData(shareCode, true);
                if (data == undefined || data == null) {
                    this.props.history.push("/NotFound");
                    //this.setState({ post: "notFound", isLoading: false, loggedInUser, shareCode, shareLink })
                } else {
                    let isFollowing = await this.fetchIsFollowing(data.user?.id);
                    this.setState({ locker: data, isFollowing, isLoading: false, loggedInUser, shareCode, shareLink });

                    this.fetchComments(0, 3);
                }
            } else {
                let trackBaseUrl = this.state.loggedInUser !== undefined ? `api/Post/TrackPostViewAuthorized` : `api/Post/TrackPostView`
                let trackPostViewUrl = `${trackBaseUrl}?userPostId=${post.id}&referrer=${document.referrer}`;

                getRequest(trackPostViewUrl);

                if (post.comments == undefined || post.comments.length == undefined) {
                    post.comments = [];
                };
                await this.setState({ locker: post, isLoading: false, loggedInUser, shareCode, shareLink });

                let isFollowing = await this.fetchIsFollowing(post.user?.id);
                this.setState({ isFollowing });
                if (post.comments.length === 0) {
                    this.fetchComments(0, 3);
                }
            }
        }

        if (this.commentsTextArea?.current != null && this.props.isModal === true && this.props.isCommenting === true) {
            this.commentsTextArea.current.scrollIntoView();
            this.commentsTextArea.current.focus();
        }

        if (this.props.isModal === false && this.props.location.state.isAddingProducts === true) {
            //this.toggleModal();
        }

        document.addEventListener("_productAddedEvent", this.refreshLocker);

        window.addEventListener('storage', () => {
            var productAdded = new Date(window.localStorage.getItem('productAdded'));
            if (productAdded < new Date()) {
                this.refreshLocker();
            }
        });

        const savedByUser = this.props?.match?.params?.post?.savedByUser;
        this.setState({ savedByUser });

        let isFollowing = await this.fetchIsFollowing(this.state.locker.user?.id);
        this.setState({ isFollowing });

        // THIS IS LOGIC TO CALCULATE THE HEIGHT OF THE CONTENT <p> FOR 'SEE MORE'
        // FOR DEVICE SIZES THAT ARE NOT SMALL - it determines if height of ProfileInfoCard 
        // is less than the expanded < p > and then puts it in contentOverflowed as well as 
        // calculate the height of the of the 'SEE MORE' <p> based on it's expanded size vs.
        // the size of the ProfileInfoCard and applies it to the styling.

        // SMALL DEVICES SET HEIGHT TO 2 LINES OF TEXT WHICH IS ~40px
        let contentOverflowed = false;
        let bioHeight;
        let titleHeight;
        let contentHeight;

        if (this.props?.deviceSize === "sm") {
            if (this.contentRef?.current?.offsetHeight > 40) {
                contentOverflowed = true;
            }
        } else {
            bioHeight = this.bioRef?.current?.offsetHeight;

            this.setState({ contentStyle: this.expandedContentStyle });

            titleHeight = this.titleRef?.current?.scrollHeight;

            if (bioHeight < titleHeight) {
                contentOverflowed = true;
            }
            this.setState({ contentStyle: this.notOverflowedContentStyle })
        }

        if (this.props?.deviceSize !== "sm" && contentOverflowed) {
            this.setState({ contentStyle: this.expandedContentStyle })
            const overflow = titleHeight - bioHeight;

            contentHeight = this.contentRef?.current?.offsetHeight;

            const maxHeight = contentHeight - overflow > 40 ? Math.floor((contentHeight - overflow) / 20) * 20 - 20 + "px" : "41px";

            this.setState({ maxHeight, contentStyle: this.notOverflowedContentStyle })
        }

        this.collapsedContentStyle = { marginTop: "0em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden" };
        this.notOverflowedContentStyle = { marginTop: "0em", marginBottom: "0.5em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden" };
        this.expandedContentStyle = { marginTop: "0em" };

        if (contentOverflowed) {
            this.setState({ contentOverflowed: contentOverflowed, contentStyle: this.collapsedContentStyle });
        } else if (!contentOverflowed && this.props.deviceSize !== "sm") {
            this.setState({ contentStyle: this.expandedContentStyle })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.loggedInUser != this.props.loggedInUser) {
            this.setState({ loggedInUser: this.props.loggedInUser })
        }
        if (this.props?.match?.params?.shareCode != prevProps?.match?.params?.shareCode) {

            this.setState({ isLoading: true });

            let shareCode = this.props.match.params.shareCode;
            let isAuthorized = this.props.loggedInUser !== null;
            let post = this.props.location?.state?.destinationState?.params?.post

            if (post == null || post == undefined) {
                post = await this.getPageData(shareCode, isAuthorized);

                if (post == null || post == undefined) {
                    this.props.history.push("/NotFound");
                    return -1
                }
                else {
                    let isFollowing = await this.fetchIsFollowing(post.user?.id);

                    this.setState({ isFollowing });
                }
            }

            this.setState({ locker: post, isLoading: false });
        }
    }

    removeCommentUsername = text => {
        const regex = /#!\^.*?\)\)\)/g;
        return text.replace(regex, '');
    }   

    fetchIsFollowing = async (userId) => {
        let url = `/api/Account/IsFollowing?id=${userId}`;
        let data = await getRequest(url);
        return data;
        //this.setState({ isFollowing: data });
    }

    componentWillUnmount() {
        document.removeEventListener("_productAddedEvent", this.refreshLocker);
        window.removeEventListener("storage", () => { });
    }

    followAction = async () => {
        this.setState({ isFollowing: !this.state.isFollowing })

        let endpoint = this.state.isFollowing === true ? 'unfollow' : 'follow';
        let url = `/api/account/${endpoint}?id=${this.state.locker.user.id}`
        let data = await getRequest(url);
    }

    renderFollowButton() {
        if (this.state.isFollowing != undefined && this.state.isFollowing != null && (this.props.loggedInUser == null || this.props.loggedInUser.id !== this.state.locker.user.id)) {
            return (
                <button onClick={() => {
                    if (this.props.loggedInUser) {
                        this.followAction()
                    } else {
                        this.props.toggleSignUpModal();
                    }
                }}
                    className="btn modal-follow-btn">{this.state.isFollowing ? "Unfollow -" : "Follow +"}</button>
            )
        }
    }

    renderInfoOverlay = (product) => {
        return <span className="info-overlay position-absolute" onClick={() => {
            this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    renderThumbsUpOverlay = (product) => {
        const productId = !!(product.productId) ? product.productId : product.id;
        if (!(product.creatorCount) || product.creatorCount === 0) {
            return <></>;
        }

        return (
            <span className="thumbs-up-overlay position-absolute" onClick={() => this.toggleCreatorsModal(productId)} style={{ cursor: "pointer", top: "15px" }}>
                <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
                <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
            </span>
        )

    }

    toggleCreatorsModal = (productId) => {
        const followersFollowing = "creators";
        this.props.toggleFollowersFollowingModal(!!(this.props.loggedInUser) ? this.props.loggedInUser.id : -1, followersFollowing, productId);
    }

    addProductsToLocker = async (products) => {
        let newState = { ...this.state };
        products.forEach((prod) => prod.coupon = prod.shareCode);
        let productMap = products.map((prod) => {
            return {
                userMerchantProduct: { ...prod, product: prod }
            }
        });
        newState.locker.productItems.$values = [...this.state.locker.productItems.$values, ...productMap];

        this.setState({ locker: newState.locker });

        var userMerchantProductIds = products.map((prod) => prod.userMerchantProductId);
        var added = await postRequest(`/api/Post/addPostProducts?shareCode=${this.state.locker.shareCode}`, userMerchantProductIds);

        await this.getPageData(this.state.locker.shareCode, true);
    }

    toTitleCase = (string) => {
        if (!!(string)) {
            return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
                return match.toUpperCase();
            });
        }
        return string;
    }   

    displayProductImages = () => {
        return trimProductsArray(this.state.locker.productItems?.$values)?.sort(function (a, b) {
            return a.ordinal - b.ordinal;
        }).map((val, i) => {
            if (this.state.isEditingProducts === true) {
                return (
                    <React.Fragment key={i}>
                        <div>
                            <button type="button" className="close" aria-label="Close"
                                style={{ display: "inline-block" }}
                                onClick={() => this.setState({ deletingProduct: { isDeleting: true, coupon: val.userMerchantProduct.coupon } })}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="col-4 col-md-3 px-1 py-1">
                            <div className="post-page-product">
                                <div className="product-image-in-page">
                                    <PostPageProduct
                                        deviceSize={this.props.deviceSize}
                                        comment={val.comment || null}
                                        product={val}
                                        productKey={val.shareCode} key={i}
                                        isEditing={this.state.isEditingProducts}
                                        showDescription={(i) => this.toggleDescriptionModal(i)}
                                        post={this.state.locker}
                                        loggedInUser={this.state.loggedInUser}
                                        locker={true}
                                    />
                                </div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                                    <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{val.manufacturer?.toLowerCase().includes("rcktemp") ? val.merchant : val.manufacturer}{val.sale && !!(val.salePrice) && !["sm", "md"].includes(this.props.deviceSize) ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                                    </div>
                                    <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}>
                                            <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                                    {this.toTitleCase(val.name)}
                                                </div>
                                            </div>
                                        </h5>
                                    </div>
                                    <div className={`users user-tile-general py-1 ml-${val.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{val.sale && !!(val.salePrice) ? "$" + val.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: val.sale && !!(val.salePrice) ? "10px" : "" }} className={`${val.sale && !!(val.salePrice) ? "on-sale-price" : ""}`}>${val.sale && !!(val.salePrice) && !!(val.originalPrice) ? val.originalPrice.toFixed(2) : val.price.toFixed(2)}</div></div></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            } else {
                return (
                    <div className="col-4 col-md-3 px-1 py-1" key={i}>
                        <div className="post-page-product">
                            <div className="product-image-in-page">
                                {this.renderInfoOverlay(val)}
                                {this.renderThumbsUpOverlay(val)}
                                <PostPageProduct
                                    loggedInUser={this.state.loggedInUser}
                                    deviceSize={this.props.deviceSize}
                                    comment={val.comment || null}
                                    product={val}
                                    productKey={val.shareCode} key={i}
                                    showDescription={(i) => this.toggleDescriptionModal(i)}
                                    post={this.state.locker}
                                    locker={true}
                                    parentModal={this.props.isModal === true ? { type: "locker", shareCode: this.props.match.params?.post?.shareCode, parentState: { ...this.state.locker } } : null}
                                />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                                <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                    <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{val.manufacturer?.toLowerCase().includes("rcktemp") ? val.merchant : val.manufacturer}{val.sale && !!(val.salePrice) && !["sm", "md"].includes(this.props.deviceSize) ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                                </div>
                                <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                                    <h5 style={{ marginBottom: "0px" }}>
                                        <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                                {this.toTitleCase(val.name)}
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                                <div className={`users user-tile-general py-1 ml-${val.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                    <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{val.sale && !!(val.salePrice) ? "$" + val.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: val.sale && !!(val.salePrice) ? "10px" : "" }} className={`${val.sale && !!(val.salePrice) ? "on-sale-price" : ""}`}>${val.sale && !!(val.salePrice) && !!(val.originalPrice) ? val.originalPrice.toFixed(2) : val.price.toFixed(2)}</div></div></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        })
    }

    displayComments = () => {
        let comments = this.state.locker?.comments;
        if (comments && comments.length && comments.length > 0) {

            return comments.map((comment, i) => {
                return <CommentTile
                    comment={comment}
                    loggedInUser={this.state.loggedInUser}
                    key={i}
                    likeAction={this.likeComment}
                    commentKey={i}
                />
            })
        }
    }

    displayCommentForm = () => {
        if (this.state.loggedInUser) {
            return (
                <div className="container comment-block pt-0 pb-0" id="locker-page-comments">
                    <div className="row mt-0">
                        <CommentForm
                            user={this.state.locker?.user}
                            commentText={this.state.comment}
                            handleCommentInput={(e) => this.setState({ comment: e.target.value })}
                            postComment={this.postComment}
                            commentsTextArea={this.commentsTextArea}
                            submittingComment={this.state.submittingComment}
                        />
                    </div>
                </div>
            )
        }
    }

    displayCommentSectionHeading = () => {
        let totalComments = this.state.locker.commentCount;
        let count = this.state.locker.comments.length;

        if (count === 0) {
            return "No Comments";
        }
        else if (count === 1) {
            return `${count} comment`;
        }
        else {
            return `${count} of ${totalComments} comments`;
        }
    }

    displayEditLockerLink = () => {
        if (this.state.loggedInUser?.id === this.state.locker.user?.id) {
            return (
                <Link to={`/Locker/NewLockerPage/${this.state.locker.shareCode}?edit=true`}>
                    <button className="btn btn-secondary btn-lg profile-banner-button" style={{ position: "absolute", zIndex: 3 }}>Edit Locker</button>
                </Link>
            )
        }
    }

    displayBanner = () => {
        let properties = this.state.locker?.properties?.$values;
        let imageSrc = properties?.length > 0 ? properties[0].value : "images/blank-banner.png"
        return (
            <>
                <ImageWrapper src={imageSrc} alt="" className="img-fluid position-absolute" style={{ objectFit: "cover", filter: "blur(5px) opacity(0.5)" }} role={properties == undefined ? 2 : properties[0]?.role} lazy={false} />

                <ImageWrapper src={imageSrc} alt="" className="img-fluid position-relative" style={{ zIndex: 2, objectFit: "contain" }} role={properties == undefined ? 2 : properties[0]?.role} lazy={false} />
            </>
        );
    }

    renderBookmarkOverlay = () => {
        const shareCode = this.props?.match?.params?.post?.shareCode;
        return (
            <span className="locker-page-bookmark-overlay position-absolute" onClick={(e) => {
                if (this.props.loggedInUser) {
                    e.preventDefault();
                    let savedByUser = !this.state.savedByUser;
                    this.setState({ savedByUser });
                    this.saveLocker(shareCode);
                } else {
                    this.props.toggleSignUpModal();
                }

            }} style={{ cursor: "pointer" }}>
                <BookmarkIcon height="16" width="16" fill={this.state.savedByUser ? "#e55934" : "none"} />
            </span>);
    }

    renderShareOverlay = () => {
        return (<span className="locker-page-share-overlay position-absolute" onClick={() => {
            this.props.toggleShareModal(this.state.shareLink)
        }} style={{ cursor: "pointer" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    displayProductEditButtons = () => {
        if (this.state.loggedInUser?.id === this.state.locker?.user?.id && this.props.modal === false) {
            return (
                <div className={`col-12 px-0 my-2 product-btn-block d-flex flex-wrap`}>
                    <button onClick={this.toggleModal}
                        className="btn btn-primary add d-flex justify-content-center mb-1 mb-md-0">
                        <PlusIcon />Add a Product
                    </button>

                    <button className="btn btn-secondary edit d-flex justify-content-center"
                        onClick={() => {
                            let isEditing = !this.state.isEditingProducts;
                            this.setState({ isEditingProducts: isEditing })
                        }}>
                        <GridIcon />
                        {this.state.isEditingProducts === false ? "Edit Products" : "Cancel"}
                    </button>
                </div>
            )
        }
    }

    displayTags = () => {
        return this.state.locker.sectors?.$values?.map((tag, i) => {
            return (
                <button key={i} className="btn btn-secondary btn-sm d-flex align-items-center mx-1" disabled>
                    {tag.sector.name}
                </button>
            )
        })
    }

    getPageData = async (shareCode, isAuthorized) => {
        let url = isAuthorized === true ? `/api/post/getPostAuthorized?shareCode=${shareCode}&referrer=${document.referrer}` : `/api/post/getPost?shareCode=${shareCode}&referrer=${document.referrer}`;
        let response = await getRequest(url);
        return response;
        //this.setState({ locker: response });
    }

    fetchComments = async (skip = this.state.locker.comments.length, take = 25) => {
        return;
        this.setState({ loadingComments: true });
        let baseUrl = this.state.loggedInUser !== undefined ? `/api/post/getCommentsAuthorized` : `/api/post/getComments`;
        let url = `${baseUrl}?shareCode=${this.state.shareCode}&skip=${skip}&take=${take}`;
        let data = await getRequest(url);
        let newState = { ...this.state };
        newState.locker.comments = [...data, ...this.state.locker.comments];

        this.setState({ ...newState, loadingComments: false });
    }

    likeLocker = async (shareCode) => {
        let num = this.state.locker.likedByUser === true ? -1 : 1;
        let newLikes = {
            likeCount: this.state.locker.likeCount + num,
            likedByUser: !this.state.locker.likedByUser
        }
        let newLocker = { ...this.state.locker, ...newLikes };

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.likedByUser = newLocker.likedByUser;
            this.props.match.params.post.likeCount = newLocker.likeCount;
        }

        this.setState({ locker: newLocker }, async () => {
            let url = `/api/post/likePost?shareCode=${shareCode}`
            let data = await postRequest(url);
        });
    }

    likeComment = async (comment, key) => {
        let num = this.state.locker.comments[key].likedByUser === true ? -1 : 1;
        let newState = { ...this.state };
        newState.locker.comments[key].likes += num;
        newState.locker.comments[key].likedByUser = !newState.locker.comments[key].likedByUser;

        this.setState(newState, async () => {
            let requestObject = {
                id: comment.id,
                likedByUser: num < 0
            }
            let url = `/api/post/likeComment`;
            let data = await postRequest(url, requestObject);
        });
    }

    messageUser() {
        alert("message user clicked...");
    }

    handleTextMarkup = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal, true);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    postComment = async () => {
        this.setState({ submittingComment: true });

        let dataToPost = { text: this.state.comment };
        let shareCode = this.state.locker.shareCode;
        let url = `/api/post/postComment?shareCode=${shareCode}&postType=${this.state.locker.type}`;

        let today = (new Date()).toLocaleString(Date.now());

        let dummyComment = {
            likedByUser: false,
            likes: 0,
            replyCount: 0,
            text: this.state.comment,
            user: this.state.loggedInUser,
            createdOn: today
        };

        let comments = [...this.state.locker.comments];
        comments.push(dummyComment);

        let newState = { ...this.state };
        newState.comment = "";
        newState.locker.comments = comments;
        newState.locker.totalCount += 1;
        newState.locker.commentCount += 1;

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.comments = [...newState.locker.comments];
            this.props.match.params.post.commentCount = newState.locker.commentCount;
            this.props.match.params.post.totalCount = newState.locker.totalCount;
        }

        this.setState(newState, async () => {
            await postRequest(url, dataToPost);
            this.setState({ submittingComment: false });
        });
    }

    refreshLocker = async () => {
        if (this.state.locker.user.id === this.props.loggedInUser?.id) {
            let shareCode = this.state.locker.shareCode;
            let isAuthorized = this.props.loggedInUser === undefined ? false : true;
            let locker = await this.getPageData(shareCode, isAuthorized);
            if (locker) {
                this.setState({ locker });
            }
        }
    }

    removeProductFromLocker = async (coupon) => {
        let updatedLocker = { ...this.state.locker };
        let location = updatedLocker.productItems.$values.findIndex(prod => prod.userMerchantProduct.coupon === coupon);
        let product = updatedLocker.productItems.$values.find(prod => prod.userMerchantProduct.coupon === coupon);
        if (location > -1) {
            updatedLocker.productItems.$values.splice(location, 1);
        }

        this.setState({ locker: updatedLocker, deletingProduct: { isDeleting: false, coupon: "" } })

        let payload = {
            user: {
                id: this.state.locker.user?.id
            }
        };

        await postRequest(`/api/Locker/DeleteLockerProduct?userPostUserMerchantProductId=${product.id}`, payload);
        await this.getPageData(this.state.locker.shareCode, true);
        //alert("are you sure you want to delete this product?")
    }

    renderDescriptionModal = () => {
        if (this.state.showDescriptionModal === true) {
            let description = this.state.locker.productItems?.$values[this.state.selectedProduct]?.comment || this.state.locker.productItems?.$values[this.state.selectedProduct]?.userMerchantProduct.userDescription;
            if (this.state.isEditingProducts === true) {
                return (
                    <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                        <div className="share-block p-1" style={{ border: "none", backgroundColor: "transparent" }}>
                            <EditProductCommentForm
                                productComment={description || ""}
                                cancel={this.toggleDescriptionModal}
                                save={(description) => {
                                    this.saveDescriptionChange(this.state.selectedProduct, description)
                                    this.toggleDescriptionModal()
                                }} />
                        </div>
                    </Modal>
                )
            }
            else {
                return (
                    <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                        <div className="share-block" style={{ border: "none", backgroundColor: "transparent" }}>
                            <UserTileGeneral user={this.state.locker.user} size={"Profile"} subtitle={" "} showCheckMark={false} />
                            <p className="pt-3 mb-0">{description}</p>
                        </div>
                    </Modal>
                )
            }

        }
    }

    renderProductPagination = () => {
        if (this.state.locker.productItems?.$values?.length > 4) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.prevPage()
                        }}>
                        <span className="item-count-prev position-absolute d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <span className="item-count position-absolute d-flex justify-content-center align-items-center">
                        {this.state.locker.productItems?.$values?.length}
                    </span>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.nextPage()
                        }}>
                        <span className="item-count-next position-absolute d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    saveDescriptionChange = async (productIndex, description) => {
        let locker = { ...this.state.locker };
        locker.productItems.$values[productIndex].comment = description;
        //locker.productItems.$values[productIndex].product.userDescription = description;
        this.setState({ locker });

        let product = locker.productItems.$values[productIndex];
        let payload = {
            id: product.id,
            comment: product.comment,
            userPostId: locker.id,
            userPostProductItemTypeId: locker.productItems.$values[productIndex].type,
            userMerchantProductId: locker.productItems.$values[productIndex].userMerchantProduct.id,
            ordinal: locker.productItems.$values[productIndex].ordinal
        }

        let url = `api/Locker/UpdateLockerProductDescription?comment=${product.comment}&userPostUserMerchantProductId=${product.id}`;

        var update = await postRequest(url, payload);
        //make api call to update product
    }

    saveLocker = async (shareCode) => {
        let reqBody = { shareCode };

        let route = this.state.locker.savedByUser === false ? `/api/post/savePost` : `/api/post/removeSavedPost`;
        let data = await postRequest(route, reqBody);

        let newPost = { ...this.state.locker };
        newPost.savedByUser = !this.state.locker.savedByUser

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.savedByUser = newPost.savedByUser;
        }

        this.setState({ locker: newPost });
    }

    toggleModal = () => {
        if (this.props.isModal === true) {
            //this.props.handleAddProducts();
        } else {
            this.props.toggleProductSelectModal();
        }

    }

    toggleDescriptionModal = (shareCode) => {
        let productIndex = this.state.locker?.productItems?.$values.findIndex(locker => locker.userMerchantProduct.coupon === shareCode);

        this.setState({ showDescriptionModal: !this.state.showDescriptionModal, selectedProduct: productIndex })
    }

    prevPage = () => {
        let page = this.state.productPage - 1;
        if (page < 0) {
            page = Math.floor(this.state.locker.productItems?.$values?.length / 4);
        }

        this.setState({ productPage: page });
    }

    nextPage = () => {
        let page = this.state.productPage + 1;
        if (page * 4 > this.state.locker.productItems?.$values?.length) {
            page = 0;
        }

        this.setState({ productPage: page });
    }
    contentSection = () => {
        if (!this.state.contentOverflowed) {
            return (
                <div className="col-12 col-md-8 px-4" ref={this.titleRef} >
                    <h2 className="mb-4">{this.state.locker.title}</h2>

                    {/**Bio Text, pulled from state or redux state */}
                    <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                        {this.handleTextMarkup(this.state.locker?.content)}
                    </p>

                </div>
            )
        } else {
            if (this.state.collapsed) {
                return (
                    <>
                        <div className="col-12 col-md-8 px-4 d-flex flex-column" ref={this.titleRef}>
                            <div>
                                <h2 className="mb-4">{this.state.locker.title}</h2>

                                {/**Bio Text, pulled from state or redux state */}
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.locker?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.expandedContentStyle, collapsed: false });
                                }}>
                                    see more
                                </Link>
                            </div>
                        </div></>)

            } else {
                return (
                    <>
                        <div className="col-12 col-md-8 px-4" ref={this.titleRef}>
                            <div>
                                <h2 className="mb-4">{this.state.locker.title}</h2>

                                {/**Bio Text, pulled from state or redux state */}
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.locker?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.collapsedContentStyle, collapsed: true });
                                }}>
                                    see less
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    getPageTitle = () => {
        const shareCode = this.props.match?.params?.shareCode;

        return "RockPorch - Locker: " + shareCode;
    };


    render() {
        if (this.state.locker === "notFound") {
            return (
                <div className="container">
                    <h1>Locker not found</h1>
                    <Link to="/Explore">Explore</Link>
                </div>
            )
        }

        if (this.state.isLoading === true) {
            return (
                <>
                    < LoadSpinner />
                    {
                        !!(this.props.isModal) ?
                            <></>
                            :
                            <Helmet>
                                <title>{this.getPageTitle()}</title>
                            </Helmet>
                    }
                </>
            )       
        }

        let commentCount = this.state.locker.comments.length;
        let parsedDate = this.state.locker.postedOn ? convertToLocaleDateTimeString(new Date(this.state.locker?.postedOn), false) : "";
        let { deviceSize } = this.props;

        //if the post isn't published, redirect to HomeFeed
        if ([0, 2, 6].includes(this.state.locker.status) === true && this.state.locker.user.id !== this.state.loggedInUser?.id && this.props.isModal !== true) {
            return <Redirect to="/HomeFeed" />
        }

        return (
            <section>
                {
                    !!(this.props.isModal) ?
                        <></>
                        :
                        <Helmet>
                            <title>{this.getPageTitle()}</title>
                        </Helmet>
                }
                {this.renderDescriptionModal()}
                <ProductsSelectorModal postShareCode={this.state.locker.shareCode}
                    existingProducts={this.state.locker.productItems?.$values}
                    addToLocker={this.addProductsToLocker}
                />
                <DeleteProductConfirm
                    confirmDelete={this.removeProductFromLocker}
                    showDeletePrompt={this.state.deletingProduct.isDeleting}
                    coupon={this.state.deletingProduct.coupon}
                    cancelDelete={() => this.setState({ deletingProduct: { isDeleting: false, coupon: "" } })}
                />
                {
                    this.props.deviceSize === "sm" ? (
                        <div className={`row pl-0 mx-0 pt-0 pb-2 ${this.props.isModal === true ? "mt-0" : ""}`}
                            style={this.props?.isModal == undefined || this.props.isModal == false ? { marginTop: "70px" } : {}}>
                            <div className="col-8 mt-0 pl-2">
                                <UserTileGeneral user={this.state.locker.user} size={"Profile"} subtitle={" "} showCheckMark={false} />
                            </div>
                            <div className="col-4 mt-0 pl-2 text-right">
                                {this.renderFollowButton()}
                            </div>
                        </div>
                    ) : <span></span>
                }
                <div className="banner-wrapper">

                    <div className="row new-locker-banner-row mx-0">
                        <div className="col-12 p-0 position-relative" id="displayBanner">
                            {this.renderBookmarkOverlay()}
                            {this.renderShareOverlay()}
                            {this.displayBanner()}
                            {this.displayEditLockerLink()}
                        </div>
                    </div>
                </div>

                <section className="container user-profile pt-0 pb-3 pl-1">
                    <div className="row">
                        {this.contentSection()}

                        {/*Mini profile section */}
                        {
                            this.props.deviceSize !== "sm" ? (
                                <div ref={this.bioRef} className="col-md-4 user-info mb-5 mb-md-0 px-md-5">
                                    <ProfileInfoCard noDiv={true} user={this.state.locker?.user} followAction={this.followUser} messageAction={this.messageUser} />
                                    <div className="col-4 mt-0 pl-0 text-right">
                                        {this.renderFollowButton()}
                                    </div>
                                </div>
                            ) : <span></span>
                        }

                    </div>
                </section>

                <div className="container container-top-gap mt-1 px-3">

                    <div className={`container product-grid position-relative pt-0 ${this.state.locker.productItems?.$values?.length > 0 ? "pb-0 mb-3" : "pb-0 border border-0"}`}>
                        <div className={`row product-image-gallery ${this.state.locker.productItems?.$values?.length >= 4 ? "justify-content-left" : "justify-content-center"} px-0 position-relative mt-2`}>
                            {this.displayProductEditButtons()}
                            {this.displayProductImages()}
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

const mapDispatch = {
    toggleProductSelectModal: toggleProductSelectModal,
    toggleProductPageModal: toggleProductPageModal,
    toggleShareModal: toggleShareModal,
    toggleFollowersFollowingModal: toggleFollowersFollowingModal,
    closeAllModals: closeAllModals
}

export default connect(null, mapDispatch)(LockerPage);