import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import PlusIcon from 'components/icons/PlusIcon';
import GridIcon from "components/icons/GridIcon";
import CameraIcon from "components/icons/CameraIcon";
import ImageWrapper from "components/ImageWrapper";
import ProductsSelectorModal from "components/UserMerchantProducts/ProductsSelectorModal";
import PostPageProduct from "components/PostPageProduct";
import FormInputField from "components/FormInputField";
import Modal from "components/Modal";
import UserTileGeneral from "components/UserTileGeneral";
import MentionsInputTextArea from "components/MentionsInputWrappers/MentionsInputTextArea";
import ActionButton from "components/ActionButton";
import LoadSpinner from "components/LoadSpinner";
import SaleIcon from "components/icons/SaleIcon";
import InformationIcon from "components/icons/InformationIcon";
import DisplayProducts from "components/DisplayProducts/DisplayProducts"

import EditProductCommentForm from "views/Locker/EditProductCommentForm";

import { toggleProductSelectModal, closeAllModals } from "stateManagement/reducers/modalReducer";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { trimProductsArray } from "sharedUtils/postFunctions";
import CloseIcon from "components/icons/CloseIcon";
import { Helmet } from 'react-helmet';

import toast, { Toaster } from 'react-hot-toast';

class NewLockerPage extends React.Component {
    state = {
        bannerImage: "",
        deletingProduct: {
            isDeleting: false,
            coupon: ""
        },

        deletedLocker: false,
        isEditingProducts: false,
        isEditing: true,
        locker: {
            title: "",
            content: "",
            sectors: {
                $values: []
            },
            productItems: {
                $values: []
            },
            properties: {
                $type: "",
                values: []
            },
            shareCode: ""
        },
        unsortedLocker: {
            title: "",
            content: "",
            sectors: {
                $values: []
            },
            productItems: {
                $values: []
            },
            properties: {
                $type: "",
                values: []
            },
            shareCode: ""
        },
        loggedInUser: {},
        lockerNameChange: "",
        lockerContentChange: "",
        mentionSuggestions: [],
        publishingChanges: false,
        publishButtonMessage: {
            showMessage: false,
            messageText: ""
        },

        redirect: false,
        savingChanges: false,
        sectors: [],
        sectorFilter: "",
        selectedProduct: 0,
        selectedFiles: [],
        selectedImagePreviews: [],
        shareCode: "",
        showDescriptionModal: false,
        refreshingProducts: false,
        isForEdit: false,
        calledEditPost: false,
        draftRedirect: false,
    }

    async componentDidMount() {
        if (this.props?.location?.search?.toLowerCase().includes("?edit=true")) {
            this.setState({ isForEdit: true })
        }
        this.props.closeAllModals()
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }
        window.scrollTo(scrollOptions);

        let shareCode = this.props.match.params.shareCode;
        let locker = await this.fetchLocker(shareCode);
        this.verifyAndSetLocker(locker);
        let sectors = await this.fetchSectors();
        this.setState({ sectors });

        document.addEventListener("_productAddedEvent", this.refreshLocker);

        window.addEventListener('storage', () => {
            var productAdded = new Date(window.localStorage.getItem('productAdded'));
            if (productAdded < new Date()) {
                this.refreshLocker();
            }
        });
    }

    saveUser = async (loggedInUser) => {
        let serializedUser = {
            name: loggedInUser.name,
            firstName: loggedInUser.firstName,
            lastName: loggedInUser.lastName,
            bio: loggedInUser.bio,
            tagLine: loggedInUser.tagLine,
            website: loggedInUser.website,
            imageUrl: loggedInUser.imageUrl,
            userName: loggedInUser.userName,
            email: loggedInUser.email,

            phoneNumber: loggedInUser.phoneNumber,
            proStatusId: loggedInUser.proStatusId,
            address: loggedInUser.address,
            city: loggedInUser.city,
            state: loggedInUser.state,
            postalCode: loggedInUser.postalCode,
            country: loggedInUser.country,

            onboardingComplete: loggedInUser.onboardingComplete,
            profileBannerUrl: loggedInUser.profileBannerUrl,
            quickStart: loggedInUser.quickStart
        };

        let userUrl = '/api/Account/PostUser';
        await postRequest(userUrl, serializedUser);
    }

    async componentDidUpdate(prevProps, prevState) {
        //this.props.closeAllModals();
        if (this.props.match.params.shareCode !== prevProps.match.params.shareCode) {
            let locker = await this.fetchLocker(this.props.match.params.shareCode);            
            this.verifyAndSetLocker(locker);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("_productAddedEvent", this.refreshLocker);
        window.removeEventListener("storage", () => { });
    }

    addProductsToLocker = async (products) => {
        var userMerchantProductIds = products.map((prod) => prod.userMerchantProductId);
        var added = await postRequest(`/api/Post/addPostProducts?shareCode=${this.state.locker.shareCode}`, userMerchantProductIds);
        this.setState({ refreshingProducts: true });
        await this.refreshLocker(false);
        this.setState({ refreshingProducts: false });
    }

    removeCommentUsername = text => {
        const regex = /#!\^.*?\)\)\)/g;
        return text.replace(regex, '');
    }   
    addSectorToLocker = (sector) => {
        let lockerCopy = { ...this.state.locker };

        let postSector = {
            sector: { ...sector, id: sector.sectorId }
        }
        lockerCopy.sectors.$values.push(postSector);
        this.setState({ locker: lockerCopy });
    }

    removeSectorFromLocker = (sector) => {
        let lockerCopy = { ...this.state.locker };
        let sectorSelected = lockerCopy.sectors?.$values?.findIndex(sec => sec.sector.id === sector.sectorId);
        lockerCopy.sectors.$values.splice(sectorSelected, 1);

        this.setState({ locker: lockerCopy });
    }

    cancelChanges = async () => {
        if (this.props.location.state?.justCreated === true) {
            let url = `/api/Locker/DeleteLocker`;
            let payload = {
                user: {
                    id: this.state.locker.user.id
                },
                type: 2,
                shareCode: this.state.locker.shareCode,
                id: this.state.locker.id
            }
            await postRequest(url, payload);
            this.setState({
                deletedLocker: true,
                lockerNameChange: this.state.locker.title,
                lockerContentChange: this.state.locker.content,
                bannerImage: this.state.locker?.properties?.$values[0]?.value
            });
            return;
        } 
        this.setState({ draftRedirect: true });
        return;
    }

    displayEditNavButtons = () => {
        let disabledPublishButton = this.state.locker.properties.$values?.length < 1 && this.state.selectedFiles?.length < 1 ;

        let disabledButtonClassName = disabledPublishButton ? "disabled-primary-style-btn":"btn-primary";
        return (
            <>
                {
                    this.state.locker.status !== 1 ? (
                        <ActionButton
                            className="btn btn-secondary my-2 my-sm-0"
                            onClick={this.saveChanges}
                            isLoading={this.state.savingChanges}
                            style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}
                        >
                            Save Draft
                        </ActionButton>

                    ) : <span></span>
                }


                <span id="lockerPublishButtonContainer">
                    <ActionButton
                        onClick={disabledPublishButton ? () => {
                            toast.error(
                                <div className="p-2">
                                    <h4 className="text-center">Set Locker Image</h4>
                                    <p className="text-center mx-auto px-2 pt-2 pb-">Make sure to select a single image file</p>
                                </div>,
                                {
                                    duration: 3000,
                                    position: 'top-center',
                                    icon: null,
                                }
                            );
                        } : this.publish}
                        className={`btn btn-primary my-2 my-sm-0 ` + disabledButtonClassName}
                        style={{ marginLeft: "5px", fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}
                        isLoading={this.state.publishingChanges}
                        message={this.state.publishButtonMessage}
                        isDisabled={false}        
                    >
                        Post Locker
                    </ActionButton>
                </span>
            </>
        )

    }

    displaySectors = () => {
        let filtered = this.state.sectors.filter(sec => sec.name.toLowerCase()
            .includes(this.state.sectorFilter.toLowerCase()));

        return filtered.map((sector, i) => {
            let sectorSelected = this.state.locker.sectors?.$values?.findIndex(sec => sec.sector.id === sector.sectorId);
            if (sectorSelected > -1) {
                return (
                    <button className="btn d-flex align-items-center active" key={i} onClick={() => this.removeSectorFromLocker(sector)}>
                        {sector.name}
                    </button>
                )
            }
            else {
                return (
                    <button className="btn d-flex align-items-center" key={i} onClick={() => this.addSectorToLocker(sector)}>
                        {sector.name}
                    </button>
                )
            }
        });
    }

    displayProducts = () => {
        if (this.state.refreshingProducts === true) {
            return <LoadSpinner />
        }
        else {
            return trimProductsArray( this.state.locker?.productItems?.$values)?.map((productItem, i) => {
                let ump = productItem.userMerchantProduct;
                return (
                    <div className="col-4 col-md-3 px-1 py-1" key={i}>
                        <div className="post-page-product">
                            <div className="product-image-in-page">
                            <PostPageProduct
                                key={i}
                                product={productItem}
                                productKey={i}
                                showDescription={(i) => this.toggleDescriptionModal(i)}
                                isEditing={!this.state.isEditingProducts}
                                />
                                <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                                    <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{productItem.manufacturer?.toLowerCase().includes("rcktemp") ? productItem.merchant : productItem.manufacturer}{productItem.sale && !!(productItem.salePrice) ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                                    </div>
                                    <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{productItem.sale && !!(productItem.salePrice) ? "$" + productItem.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: productItem.sale && !!(productItem.salePrice) ? "10px" : "" }} className={`${productItem.sale && !!(productItem.salePrice) ? "on-sale-price" : ""}`}>${productItem.sale && !!(productItem.salePrice) && !!(productItem.originalPrice) ? productItem.originalPrice.toFixed(2) : productItem.price.toFixed(2)}</div></div></h5>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.isEditingProducts === true ? (
                                    <span style={{ cursor: "pointer", paddingRight: "2px" }} href="/" className="product-comment position-absolute" onClick={(e) => {
                                        e.preventDefault()
                                        this.removeProductFromLocker(productItem.shareCode)
                                    }}>
                                        {this.props.deviceSize === "sm" ? <CloseIcon /> : <CloseIcon width={12} height={12} />}
                                    </span>) : null
                            }
                        </div>
                    </div>
                )
            })
        }

    }

    displayProductDescriptionModal = () => {
        let description = this.state.locker.productItems?.$values[this.state.selectedProduct]?.comment
        return (
            <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                <div className="share-block p-1" style={{ border: "none", backgroundColor: "transparent" }}>
                    <EditProductCommentForm
                        productComment={description || ""}
                        cancel={this.toggleDescriptionModal}
                        save={async(description) => {
                            await this.saveProductDescription(this.state.selectedProduct, description)
                            this.toggleDescriptionModal()
                        }}
                    />
                </div>

            </Modal>

        )
    }

    fetchSectors = async () => {
        let url = `api/Sector/GetSectors`;
        let sectors = await getRequest(url);
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        if (sectors === undefined) {
            return [];
        }
        else {
            return sectors;
        }
    }

    fetchLocker = async (shareCode) => {
        let locker = await getRequest(`api/Post/getPostAuthorized?shareCode=${shareCode}`);
        return locker;
    }

    fetchUserSuggestions = async (query, callback) => {
        if (!query || query.length < 1) {
            return;
        }

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;
        let users = await getRequest(url);
        if (users === undefined) {
            this.setState({ mentionSuggestions: [] });
        }
        else {
            this.setState({ mentionSuggestions: users });

        }

        let mappedUsers = this.state.mentionSuggestions.map(user => ({ display: user.userName, id: user.id, name: user.name }));
        callback(mappedUsers);
    }

    publish = async () => {
        // this.state.calledEditPost ensures that /api/post/EditPost is only called once
        if (!this.state.calledEditPost) {
            this.setState({ calledEditPost: true })

            if (this.state.locker.productItems.$values.length === 0) {
                //alert("Add a product before publishing");
                this.setState({ publishButtonMessage: { showMessage: true, messageText: "Add a product before publishing" } });

                setTimeout(() => this.setState({ publishButtonMessage: { showMessage: false, messageText: "" } }), 2000)
                return -1;
            }
            else {
                this.setState({ publishingChanges: true });
                let payload = {
                    content: this.state.lockerContentChange,
                    title: this.state.lockerNameChange,
                    user: { id: this.state.locker.user.id },
                    id: this.state.locker.id,
                    statusId: 1,
                    sectors: this.state.locker.sectors.$values.map((value) => {
                        return {
                            sector: { id: value.sector.id, name: value.sector.name }
                        };
                    }),
                };

                await this.saveChanges()

                var url = `/api/post/EditPost?isPublishing=${true}&isEditing=${this.state.isForEdit}&notification=false`;

                var published = await postRequest(url, payload);

                if (published.title === "Unauthorized") {
                    this.setState({ redirect: false, publishingChanges: false, calledEditPost: false });
                    const url = `/Locker/LockerPage/${this.state.locker.shareCode}`
                    window.location.replace(url);
                    return;
                }

                if (published != undefined) {
                    //alert("Successfully published post");
                    this.setState({ publishingChanges: false, redirect: true })

                }
                else {
                    //alert("There was an issue publishing");
                    this.setState({ publishingChanges: false, calledEditPost: false })
                }
            }
        }

    }

    removeProductFromLocker = async (coupon) => {
        let updatedLocker = { ...this.state.locker };
        let location = updatedLocker.productItems.$values.findIndex(prod => prod.userMerchantProduct.coupon === coupon);
        let product = updatedLocker.productItems.$values.find(prod => prod.userMerchantProduct.coupon === coupon);
        if (location > -1) {
            updatedLocker.productItems.$values.splice(location, 1);
        }

        this.setState({ locker: updatedLocker, deletingProduct: { isDeleting: false, coupon: "" } })

        let payload = {
            user: {
                id: this.state.locker.user?.id
            }
        };

        await postRequest(`/api/Locker/DeleteLockerProduct?userPostUserMerchantProductId=${product.id}`, payload);

        if (this.state.locker.productItems.$values.length === 0) {
            let payload2 = {
                content: this.state.locker.content,
                title: this.state.locker.title,
                user: { id: this.state.locker.user.id },
                id: this.state.locker.id,
                statusId: 0
            }
            let url = `/api/post/EditPost?isPublishing=${false}&isEditing=${this.state.isForEdit}`;
            await postRequest(url, payload2);
        }
    }

    refreshLocker = async (refreshBanner = true) => {
        let shareCode = this.props.match.params.shareCode;
        let locker = await this.fetchLocker(shareCode);
        this.verifyAndSetLocker(locker, refreshBanner);
    }

    saveChanges = async () => {
        this.setState({ savingChanges: true });
        await this.uploadMedia();
        await this.updateLockerContent();
        
        let newProperties = await this.fetchLocker(this.state.locker.shareCode);
        let newLocker = { ...this.state.locker, ...newProperties }
        if (newLocker !== undefined || newLocker !== null) {
            this.setState({ locker: newLocker, selectedFiles: [], selectedImagePreviews: [], savingChanges: false });
        }
    }

    /*
    saveSectors = async () => {
        let url = `/api/Sector/UpdateUserPostSectors?postId=${this.state.locker.id}`;
        let sectors = this.state.locker.sectors.$values.map(sec => sec.sector.id);
        await postRequest(url, sectors);
    }
    */

    saveProductDescription = async (productIndex, description) => {
        let locker = { ...this.state.locker };
        locker.productItems.$values[productIndex].comment = description;
        //locker.productItems.$values[productIndex].product.userDescription = description;
        this.setState({ locker });

        let product = locker.productItems.$values[productIndex];
        let payload = {
            id: product.id,
            comment: product.comment,
            userPostId: locker.id,
            userPostProductItemTypeId: locker.productItems.$values[productIndex].type,
            userMerchantProductId: locker.productItems.$values[productIndex].userMerchantProduct.id,
            ordinal: locker.productItems.$values[productIndex].ordinal
        }

        let url = `api/Locker/UpdateLockerProductDescription?comment=${product.comment}&userPostUserMerchantProductId=${product.id}`;

        var update = await postRequest(url, payload);
    }

    selectMediaToUpload = (e) => {
        e.preventDefault();

        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (file?.type?.startsWith('image/')) {
                const url = URL.createObjectURL(file);

                this.setState({ selectedFiles: [file], bannerImage: url });
            } else {
                toast.error(
                    <div className="p-2">
                        <h4 className="text-center">Set Locker Image</h4>
                        <p className="text-center mx-auto px-2 pt-2 pb-">Make sure to select a single image file</p>
                    </div>,
                    {
                        duration: 3000,
                        position: 'top-center',
                        icon: null,
                    }
                );
            }

            e.target.value = null;
        }
    }

    toggleDescriptionModal = (shareCode) => {
        let productIndex = this.state.unsortedLocker?.productItems?.$values.findIndex(locker => locker.userMerchantProduct.coupon === shareCode);

        this.setState({ showDescriptionModal: !this.state.showDescriptionModal, selectedProduct: productIndex })
    }

    uploadMedia = async () => {
        if (this.state.selectedFiles.length > 0) {
            let url = `/api/Locker/uploadMedia?postId=${this.state.locker.id}&postUserId=${this.state.locker.user.id}`;
            let bannerExists = this.state.locker?.properties.$values.length > 0;

            if (bannerExists === true) {
                url += `&existingBanner=${this.state.locker?.properties.$values[0]?.value}&userPostPropertyId=${this.state.locker?.properties.$values[0]?.id}`
            }

            const formData = new FormData();

            for (let i = 0; i < this.state.selectedFiles.length; i++) {
                formData.append(`postImage${i}`, this.state.selectedFiles[i])
            }
            let newProperties = await postRequest(url, formData, null, false);
            return newProperties;
        }
        else return null;
    }

    updateLockerContent = async () => {
        var url = `/api/post/EditPost?isPublishing=${false}&isEditing=${this.state.isForEdit}`;

        let payload = {
            content: this.state.lockerContentChange,
            title: this.state.lockerNameChange,
            user: { id: this.state.locker.user.id },
            id: this.state.locker.id,
            sectors: this.state.locker.sectors.$values.map((value) => {
                return {
                    sector: { id: value.sector.id, name: value.sector.name }
                };
            }),
            statusId: 0
        }

        var edit = await postRequest(url, payload);
        return edit;
    }

    verifyAndSetLocker = (locker, refreshBanner = true) => {
        if (locker === undefined) {
            this.setState({ locker: "notFound" });
        }
        else {
            let shareCode = locker.shareCode;
            if (this.props.loggedInUser?.id !== locker.user?.id) {
                this.setState({ redirect: true })
            }
            else {
                let images = locker.properties?.$values;
                let imageSrc;
                if (images !== undefined && images.length > 0) {
                    imageSrc = images[0].value;
                }
                else {
                    imageSrc = "images/blank-banner.png"
                }
               
                this.setState({
                    shareCode,
                    locker,
                    unsortedLocker: locker,
                    loggedInUser: this.props.loggedInUser,
                    lockerNameChange: locker.title,
                    lockerContentChange: locker.content,
                    bannerImage: refreshBanner ? imageSrc : this.state.bannerImage,
                });
            }
        }
    }

    removeProductFromPost = async (coupon) => {
        let updatedLocker = { ...this.state.locker };
        let location = updatedLocker.productItems.$values.findIndex(prod => prod.userMerchantProduct.coupon === coupon);
        let product = updatedLocker.productItems.$values.find(prod => prod.userMerchantProduct.coupon === coupon);
        if (location > -1) {
            updatedLocker.productItems.$values.splice(location, 1);
        }

        this.setState({ locker: updatedLocker })

        let payload = {
            user: {
                id: this.state.locker.user?.id
            }
        };

        await postRequest(`/api/Locker/DeleteLockerProduct?userPostUserMerchantProductId=${product.id}`, payload);
    }

    reorderProductsInPost = (items) => {
        let reorderedItems = items.map((item, index) => {
            item.ordinal = index + 1;
            return item;
        });

        let newValues = this.state.locker?.productItems.$values.map(item => {
            for (let i = 0; i < reorderedItems?.length; i++) {
                if (reorderedItems[i].id == item.id) {
                    item.ordinal = reorderedItems[i].ordinal;
                };
            };
            return item;
        }).sort(function (a, b) {
            return a.ordinal - b.ordinal;
        });

        let locker = this.state.locker;
        locker.productItems.$values = newValues;
        this.setState({ locker });
    }

    renderInfoOverlay = (shareCode) => {
        return <span className="info-overlay-post-edit position-absolute" onClick={() => {
            this.props.toggleProductPageModal({ shareCode }, this.props.loggedInUser, false, () => null)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    getPageTitle = () => {
            const shareCode = this.props.match?.params?.shareCode;

            return "RockPorch - Edit Locker: " + shareCode;
    };

    render() {
        let { title, content } = this.state.locker;

        let productGridRowClass = ["sm", "md"].includes(this.props.deviceSize) ? "" : "";
        if (this.state.draftRedirect && this.state.isForEdit) {
            return (
                <Redirect to={`/`} />
            )
        } else if (this.state.draftRedirect) {
            return (
                <Redirect to={`/Account/Drafts`} />
            )
        } else if (this.state.locker === "notFound") {
            return (
                <Redirect to={`/`} />
            )
        } else {
            return (
                <section>
                    <Helmet>
                        <title>{this.getPageTitle()}</title>
                    </Helmet>
                    <Toaster />
                    {
                        this.state.redirect === true ? (
                            <Redirect to={`/`} />
                        ) : <span></span>
                    }
                    {this.displayProductDescriptionModal()}

                    {
                        this.state.deletedLocker === true ? (
                            <Redirect to="/" />
                        ) : <span></span>
                    }
                    <ProductsSelectorModal
                        postShareCode={this.state.locker.shareCode}
                        existingProducts={this.state.locker.productItems?.$values}
                        addToLocker={this.addProductsToLocker}
                        deviceSize={this.props.deviceSize}
                    />

                    <nav className="navbar new-locker-taskbar position-relative">
                        <div className="col-12" style={{ display: "flex", justifyContent: "space-between", margin: "auto", width: "85%" }}>
                            <h6 className="mt-3">Draft</h6>
                        </div>
                        <button className="close browse-close mr-2 position-absolute" style={{ right: "25px", top: "29%" }} onClick={this.cancelChanges} >
                            <CloseIcon />
                        </button>
                    </nav>

                    <div className="container">

                        <div className="row new-locker-banner-row">

                            <div className="col-12 p-0">
                                <div className="position-relative mb-3">
                                    <ImageWrapper src={this.state.bannerImage} className="img-fluid" style={{ objectFit: 'cover', filter: 'blur(5px) opacity(0.5)', width: "100%", height: "auto", maxWidth: "100%", maxHeight: this.props.deviceSize === "sm" ? "50vw" : "33vw", minHeight: this.props.deviceSize === "sm" ? "50vw" : "33vw", position: 'absolute', top: 0, left: 0, zIndex: 1 }} alt="banner" />
                                    <ImageWrapper src={this.state.bannerImage} className="img-fluid position-relative" style={{ zIndex: 2, objectFit: 'contain', width: "100%", height: "auto", maxWidth: "100%", maxHeight: this.props.deviceSize === "sm" ? "50vw" : "33vw", minHeight: this.props.deviceSize === "sm" ? "50vw" : "33vw" }} alt="banner" />                                    
                                </div>

                                <input type="file"
                                    accept="image/png,image/jpeg"
                                    name="files[]"
                                    id="uploadLockerImage"
                                    onChange={(e) => this.selectMediaToUpload(e)}
                                    style={{ display: "none" }}
                                />

                                {
                                    this.state.isEditing === true ? (
                                        <>
                                            <button
                                                id="cameraButton"
                                                className="btn btn-secondary profile-banner-button"
                                                style={{ zIndex: 3 }}
                                                onClick={(e) => {
                                                    document.getElementById("uploadLockerImage").click()
                                                }}
                                            >
                                                <CameraIcon />
                                                {this.props.deviceSize === "sm" ? "" : " Update Image"}
                                            </button>
                                        </>

                                    ) : null
                                }

                            </div>

                        </div>

                    </div>

                    <div className="container">
                        <div className="row mt-2">
                            <div className="col-12 px-2 position-relative">
                                <UserTileGeneral user={{ ...this.state.locker?.user }} size={"Profile"} subtitle={" "} showCheckMark={false}/>
                                <FormInputField
                                    inputType="text"
                                    title="Locker Name"
                                    name="lockerName"
                                    onInputChange={(e) => { this.setState({ lockerNameChange: e.target.value }) }}
                                    value={this.state.lockerNameChange}
                                />

                                <label className="control-label" style={{ fontFamily: "Archivo" }}>
                                    <strong>Edit Description</strong>
                                </label>

                                <MentionsInputTextArea
                                    className="caption-textarea"
                                    value={this.state.lockerContentChange}
                                    onChange={(e) => this.setState({ lockerContentChange: e.target.value })}
                                    data={this.fetchUserSuggestions}
                                />


                                <FormInputField
                                    inputType="text"
                                    title="Search Interests"
                                    name="sectorFilter"
                                    onInputChange={(e) => { this.setState({ sectorFilter: e.target.value }) }}
                                    value={this.state.sectorFilter}
                                    placeholder="Search for interests.."
                                />

                                <div className="mt-3">
                                    <div className="search-interest mx-auto">
                                        <div className="search-block">
                                            <div className="tag-results-sm d-flex justify-content-center flex-wrap py-0">
                                                {this.displaySectors()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <hr className="mt-3 mb-0" />

                    </div>

                    <div className="container product-grid pt-0 pb-2 px-2 border border-0">

                        <div className={`row mx-0 ${productGridRowClass} mt-2`}>

                            <div className="col-12 px-0 mb-1">
                                    <button className="btn btn-primary my-2 my-sm-0 ml-0"
                                        title="Add your first product"
                                        onClick={this.props.toggleModal}
                                    style={{ backgroundColor: "white", color: "#e55934", fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}
                                    >
                                        Add Product
                                    </button>

                                    <div className="action-button-wrapper">
                                        {this.displayEditNavButtons()}
                                    </div>

                            </div>

                        </div>

                        {/*<div className="row product-image-gallery justify-content-center position-relative mt-0 mx-0">*/}
                        {/*    {this.displayProducts()}*/}
                        {/*</div>*/}

                        <div className="row product-image-gallery justify-content-center position-relative mt-0 mx-0">
                            <DisplayProducts
                                products={trimProductsArray(this.state.locker?.productItems?.$values)?.sort(function (a, b) {
                                    return a.ordinal - b.ordinal;
                                })}
                                removeProductFromPost={this.removeProductFromPost}
                                reorderProductsInPost={this.reorderProductsInPost}
                                deviceSize={this.props.deviceSize}
                                postId={this.state.locker?.id}
                                renderInfoOverlay={this.renderInfoOverlay}
                                locker={true}
                                showDescription={(i) => this.toggleDescriptionModal(i)}
                            />
                        </div>

                    </div>

                </section>
            )
        }

    }
}

const mapDispatch = {
    toggleModal: toggleProductSelectModal,
    closeAllModals
}

export default connect(null, mapDispatch)(NewLockerPage);