import React, { useState, useEffect, useLayoutEffect } from "react";
import LoadSpinner from "components/LoadSpinner";
import YoutubeIcon from "components/icons/YoutubeIcon";
import { getRequest } from "sharedUtils/httpUtils";
import PostBanner from "./PostBanner";

/**
 * 
 * @param {{src:string, alt:string, style:object, className:string, role:Number, onClick:Function, videoWidth:Number}} props
 */
const ImageWrapper = (props) => {

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    useLayoutEffect(() => {

        //new window.YT.Player('player' + genId, {
        //events: {
        //'onStateChange': (event) => {
        //if (event.data === 1) {
        //if (props.onClick) { event.target.stopVideo(); props.onClick(); }
        //}
        //}
        //}
        //});

        return () => {
            let video = document.getElementById(genId);

            if (video) {
                video.pause();
                video.currentTime = 0;
            }
        }
    }, []);

    const [html, setHtml] = useState(null);
    const [fallbackMessage, setFallbackMessage] = useState("none");
    const containerRef = React.useRef(null);

    useEffect(() => {
        if (props.src?.includes("blockquote") && !!(containerRef.current)) {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.tiktok.com/embed.js?20230131';
            containerRef.current.appendChild(script);
            return () => {
                script.remove();
            };
        }
    }, []);
    
    let [imageLoaded, setImageLoaded] = useState(false);
    let [genId, setGenId] = useState(uuidv4());
    const [reloadKey, setReloadKey] = useState(0);

    let emptyClick = (e) => e.preventDefault
    let isFeed = (props.size) && ["Profile", "Product", "ProductGrid", "Post"].includes(props.size);

    const resizeImages = url => {
        getRequest(url);
    }

    if (props.role && props.role === 1) {
        let overlayStyle = { position: "absolute", zIndex: 3, cursor: "pointer", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

        return (
            <React.Fragment key={reloadKey}>
                <video
                    id={genId}
                    onClick={(e) => {
                        e.preventDefault();
                        if (props.onClick === undefined) {
                            emptyClick(e);
                        } else {
                            document.getElementById(genId).pause();
                            props.onClick();
                        }
                    }}
                    className={props.className}
                    poster={props.poster}
                    preload="metadata"
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                    onCanPlay={() => {
                        document.getElementById(genId).style.display = ''; // Show the fallback message
                        setFallbackMessage("none")

                        if (typeof props.setVideoLoaded === "function") {
                            props.setVideoLoaded(true);
                        }
                    }}
                    onError={() => {
                        setFallbackMessage("");
                        document.getElementById(genId).style.display = 'none'; // Show the fallback message
                        setTimeout(() => {
                            setReloadKey(reloadKey + 1); // Update the reload state to force component re-render
                        }, 150000); // 15 seconds
                        if (typeof props.setVideoLoaded === "function") {
                            props.setVideoLoaded(false);
                        }
                    }}
                >
                    {/* Specify video source */}
                    <source src={props.src.split('#')[0] + "#t=0.001"} />
                </video>
                
                {/* Fallback content displayed if video cannot be played */}
                <div id="fallback-message" onClick={props.onClick || emptyClick} style={{ display: fallbackMessage, position: props.postBanner ? "relative" : "" }}>
                    <div className="spinner-border spinner-border-sm" style={{ position: "absolute", zIndex: 3, cursor: "pointer", top: "50%", left: "50%" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <img src={`images/blank-banner.png`}                        
                        alt={props.alt || ""}
                        style={{ height: props.postBanner ? "300px" : "", objectFit: props.postBanner ? "cover" : "" }}
                        className={props.className || ""}
                        onClick={props.onClick || emptyClick}
                    />
                    <div style={{ position: "absolute", top: props.postFeedPostGridItem ? "15%" : "20px", transform: props.postFeedPost ? "translate(-47%, 0)" : "translate(-50%, 0)", left: "50%", width: "65%" }}>
                        <p style={{ fontWeight: "bold" }}>Please be patient! We are processing this video.</p>
                        <p style={{ fontSize: "14px", display: props.postBanner && !(props.postPage) ? "" : "none" }}>It will be ready in a few minutes. You can wait to publish this post or save it to drafts and come back later.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    else if (props.role === 3) {
        let paddingTop = 9 / 16 * 100 + '%';
        const tikTokPadding = props.src.toLowerCase().includes("tiktok");
        if (!!props.width && !!props.height) {
            if (props.width < props.height) {
                paddingTop = props.width / props.height * 100 + '%';
            } else {
                paddingTop = props.height / props.width * 100 + '%';
            }
        }

        if (props.src.includes("blockquote")) {
            return (
                <div style={{ textAlign: "center", overflow: "hidden", position: "relative" }}>
                    {/*    <div style={{ minHeight: "790px", paddingTop: "25px" }} ref={containerRef}>{!!(html) ? html : <></>}</div>*/}
                    <div style={{ margin: "0 auto", maxWidth: "323px", height: "790px", paddingTop: "25px" }} ref={containerRef} dangerouslySetInnerHTML={{ __html: props.src }} />
                </div>
            )
        }

        return (
            <div style={{ textAlign: "center", overflow: "hidden", paddingTop: `${tikTokPadding ? "770px" : paddingTop}`, position: "relative" }}>
                <iframe id={'player' + genId} src={props.src} style={{ border: "none", left: 0, top: 0, position: "absolute" }} height="100%" width="100%">
                </iframe>
            </div>
        )
    }else {
        let overlayStyle = { position: "absolute", width: "100%", height: "100%", zIndex: 3, padding: "47%", cursor:"pointer" };
        if (props.lazy != null && props.lazy === false) {
            return (
                <img src={props.src}
                    onError={(e) => {
                        e.target.onError = null
                        e.target.src = "images/blank-banner.png"
                    }}
                    onLoad={(e) => {
                        setImageLoaded(true)
                    }}
                    alt={props.alt || ""}
                    style={props.style || {}}
                    className={props.className || ""}
                    onClick={props.onClick || emptyClick}
                />
            )
        }
        return (
            <>
                {props.role === 8 ? <div style={overlayStyle} onClick={props.onClick || emptyClick}><YoutubeIcon/></div> : null}
                <img src={`${props.src}`}
                    onError={(e) => {
                        e.target.onError = null
                        if (isFeed) {
                            if (props.size === "Profile") {
                                e.target.src = `images/avatar2.svg`
                            } else {
                                e.target.src = "images/blank-banner.png"
                            }
                        } else {
                            e.target.src = "images/blank-banner.png"
                        }
                    }}
                    onLoad={(e) => {
                        setImageLoaded(true)
                    }}
                    alt={props.alt || ""}
                    style={props.style || {}}
                    className={props.className || ""}
                    onClick={props.onClick || emptyClick}
                    loading={props.lazy != null && props.lazy == false ? "" : "lazy"}
                />
            </>
        )
    }

}


export default ImageWrapper;