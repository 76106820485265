import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import ActionButton from "components/ActionButton";

import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

import GridIcon from "components/icons/GridIcon";
import DoubleBarIcon from "components/icons/DoubleBarIcon";
import PlusIconNav from "components/icons/PlusIconNav";
import CloseIcon from "components/icons/CloseIcon";

import { toggleFeedLayout } from "stateManagement/reducers/feedLayoutReducer";
import { toggleAddProductModal, toggleCreateLockerModal, toggleShareModal, toggleSharePostModal, toggleShareBlogModal, toggleShareDeepLinkModal, toggleAddProductOrDeepLinkModal } from "stateManagement/reducers/modalReducer";

import { postRequest } from '../../sharedUtils/httpUtils';
import { getMessageCount, getNotificationCount } from "sharedUtils/notificationUtils";

class LoginMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            showSharePostModal: false,
            searchValue: "",
            redirectValue: "",
            redirectSearch: false,
            showProfilePicturePrompt: false,
            showPrompt: {},
        };

        this.clickHandler = this.clickHandler.bind(this);
        this.dropDownIconRef = React.createRef();
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        await this.populateState();

        if (this.props.loggedInUser?.imageUrl === null || this.props.loggedInUser?.imageUrl === undefined) {
            this.setState({ showProfilePicturePrompt: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let element = document.getElementById('createLockerPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }

        element = document.getElementById('sharePostPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }

        element = document.getElementById('shareBlogPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }

        element = document.getElementById('shareDeepLinkPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }


        if (this.state.redirectSearch === true) {
            this.setState({ redirectSearch: false })
        }

        if (!!(this.props.searchProp) && this.props.searchProp.length > 0 && this.props.searchProp !== this.state.searchValue) {
            const searchValue = this.props.searchProp.includes("*&^reset") ? "" : this.props.searchProp;
            this.props.resetSearchProp(!!(searchValue) ? true : false);
            this.setState({ searchValue });
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    clickHandler(e) {
        e.preventDefault();
        this.toggleModal();
    }

    displayLayoutToggleButton = () => {

        return (
            <Link to="/" onClick={(e) => {
                e.preventDefault();
                this.props.toggleFeedLayout();
            }}
            >
                {this.props.feedLayout.isGrid === true ? <DoubleBarIcon /> : <GridIcon />}
            </Link>
        )
    }

    togglePrompt = (id) => {
        let showPrompt = { ...this.state.showPrompt };
        showPrompt[id] = !showPrompt[id];

        this.setState({ showPrompt });
    }

    mapAddIconDropdown = () => {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        let menu = [
            {
                title: "Create a Commissionable Link",
                action: this.props.toggleAddProductOrDeepLinkModal,
                iconPath: "images/Thunder.svg",
            },
            {
                title: "Create a Locker",
                action: this.props.toggleCreateLockerModal,
                iconPath: "images/Difference.svg",
                infoPrompt: "Lockers are pursuit or destination specific gear lists",
                infoPromptId: "createLockerPrompt"
            },
            {
                title: "Share a Post",
                action: this.props.toggleSharePostModal,
                iconPath: "images/Picture.svg",
                infoPrompt: "Integrate your social accounts to repost content and tag products",
                infoPromptId: "sharePostPrompt"
            },
            {
                title: "Share a Blog Article",
                action: this.props.toggleShareBlogModal,
                iconPath: "images/Blog.svg",
                infoPrompt: "Share a blog article and tag products",
                infoPromptId: "shareBlogPrompt"
            },
        ]

        return menu.map((menuItem, i) => {
            const blog = menuItem.title === "Share a Blog Article";
            const deepLink = menuItem.title === "Share a Deep Link";

            return (
                <li key={i} style={{ display: "flex", justifyContent: "start" }}>
                    <a href="#" className={`dropdown-item override-active nav-link new-nav-dropdown`}
                        onClick={(e) => {
                            e.preventDefault();
                            menuItem.action();
                        }}
                    >
                        {menuItem.title}
                    </a>
                </li>
            )
        })
    }

    mapThreeDotIconDropdown = () => {
        let menu = [
            { title: "Explore", path: "/nav/Explore?showbrandNav=true" },
            { title: "My Feed", path: "/HomeFeed" },
            { title: "About", path: "https://www.rockporch.com/home", target: "blank_" },
            { title: "Terms of Service", path: "https://www.rockporch.com/terms/", target: "blank_" },
            { title: "Privacy Policy", path: "https://www.rockporch.com/privacy-policy/", target: "blank_" },
            { title: "Contact", path: "/Contact" },
        ]

        if (this.props.loggedInUser?.proStatusId === 1) {
            menu = [
                { title: "Profile", path: `/Profile/${this.props?.loggedInUser?.userName}` },
                { title: "Explore", path: "/nav/Explore?showBrandNav=true" },
                { title: "My Feed", path: "/HomeFeed" },
                { title: "FAQ", path: "https://www.rockporch.com/faq/", target: "blank_" },
                { title: "About", path: "https://www.rockporch.com/home", target: "blank_" },
                { title: "Terms of Service", path: "https://www.rockporch.com/terms/", target: "blank_" },
                { title: "Privacy Policy", path: "https://www.rockporch.com/privacy-policy/", target: "blank_" },
                { title: "Contact", path: "/Contact" },
            ];
        }

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink tag={Link} to={nested.path} className="dropdown-item" onClick={(e) => e.preventDefault()}>{nested.title}</NavLink>
                    </li>
                )
            })
            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <a className="dropdown-item nav-link" href={menuItem.path} target={menuItem.target}>{menuItem.title}</a>
                            :
                            <NavLink tag={Link} to={menuItem.path} className="dropdown-item">{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({
            isAuthenticated: isAuthenticated
        }, () => {
            if (isAuthenticated) {
            }
        });
    }

    redirect = () => {
        return <></>;
    //    return <Link to={`/SearchResults/${this.state.redirectValue}`} id="redirectLink" style={{ display: "hidden" }}></Link>
    }

    renderLoginDropdown(logoutPath) {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        const registerPath = `${ApplicationPaths.Register}`;
        const link = document.location;
        const returnUrl = `${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`;

        if (this.props.loggedInUser?.proStatusId === 1 && this.props.creatorCentricView) {
            return (
                <>
                    <NavItem className="dropdown login-dropdown" style={{ display: "inline" }}>
                        <NavLink tag={Link}
                            className="btn dropdown-toggle p-0 d-flex justify-content-center align-items-center"
                            type="button"
                            id="profile-dropdown"
                            to="/"
                            data-toggle="dropdown"
                        >
                            {/*{*/}
                            {/*    isSmallDevice ? */}
                            {/*        <span className="navbar-toggler-icon" style={{ transform: "scale(1.3)" }}></span> */}
                            {/*        :*/}
                            {/*    this.props?.loggedInUser?.imageUrl ? (< img id="profilePicId" src={this.props.loggedInUser.imageUrl} alt=""></img>) : < img id="profilePicId" src="/images/avatar2.svg" alt=""></img>*/}
                            {/*}*/}

                            {this.props?.loggedInUser?.imageUrl ? (< img id="profilePicId" src={this.props.loggedInUser.imageUrl} style={{ marginTop: this.props.screenWidth > 991 ? "2px" : "-11px" }} alt=""></img>) : < img id="profilePicId" src="/images/avatar2.svg" style={{ marginTop: "2px" }} alt=""></img>}

                        </NavLink>

                        <ul className={`dropdown-menu`} style={{ right: this.props.screenWidth < 769 && this.props.screenWidth > 450 ? "-50px" :  isSmallDevice ? "-10px" : "-200px" }}  aria-labelledby="dropdownMenuButton" >
                            <li>
                                <NavLink tag={Link} to={`/CreatorDashboard?tab=0`} className="dropdown-item override-active new-nav-dropdown">My Profile</NavLink>
                            </li>
                            {this.mapAddIconDropdown()}                          
                            <li>
                                <NavLink tag={Link} to="/Account/Analytics" className="dropdown-item override-active new-nav-dropdown">Analytics</NavLink>
                            </li>
                            <li>
                                <NavLink tag={Link} to="/Account/Drafts" className="dropdown-item override-active new-nav-dropdown">Drafts</NavLink>
                            </li>
                            <li>
                                <NavLink tag={Link} to="/Explore" className="dropdown-item override-active new-nav-dropdown">Explore</NavLink>
                            </li>
                            {
                                isSmallDevice ?
                                    <>
                                        <hr className="my-1" />
                                        <li>
                                            <NavLink tag={Link} to="/Browse" className="dropdown-item override-active new-nav-dropdown">Shop</NavLink>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <a href="https://www.rockporch.com/CREATOR-RESOURCES/" className="dropdown-item override-active new-nav-dropdown" style={{ color: 'black' }}>
                                                    <span>Resources</span>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.collapseMenu();
                                                    this.props.toggleShareModal(this.props.getReferralShareLink(this.props.loggedInUser?.userName))
                                                }}
                                                className="dropdown-item override-active new-nav-dropdown">Refer A Creator</a>
                                        </li>

                                    </>
                                    :
                                    <></>
                            }
                            <hr className="my-1" />
                            {
                                this.props?.loggedInUser?.isAdmin === true ? (
                                    <li>
                                        <NavLink tag={Link} to="/Admin" className="dropdown-item override-active new-nav-dropdown" onClick={() => { this.props.setShowTopNav(false) }}>Admin</NavLink>
                                    </li>
                                ) : <span></span>
                            }
                            <li>
                                <NavLink tag={Link} to={logoutPath} className="dropdown-item override-active new-nav-dropdown" onClick={() => { this.props.setShowTopNav(false) }}>Log Out</NavLink>
                            </li>
                        </ul>
                    </NavItem>
                    <span className="ml-1" style={{ display: isSmallDevice ? "none" : "", fontFamily: "Archivo, sans-serif", fontWeight: "bold", fontSize: "14px", marginTop: "11px" }}> { this.props.loggedInUser.userName }</span>
                    
                </>
            );
        } else {
            return <></>;
        }
    }

    setDefaultProfilePicture = async () => {
        let { props } = this;
        let userDetails = {
            name: props.loggedInUser.name,
            firstName: props.loggedInUser.firstName,
            lastName: props.loggedInUser.lastName,
            bio: props.loggedInUser.bio,
            tagLine: props.loggedInUser.tagLine,
            website: props.loggedInUser.website,
            googleUsername: props.loggedInUser.googleUsername,
            facebookUsername: props.loggedInUser.facebookUsername,
            instagramUsername: props.loggedInUser.instagramUsername,
            userName: props.loggedInUser.userName,
            email: props.loggedInUser.email,

            phoneNumber: props.loggedInUser.phoneNumber,
            proStatusId: props.loggedInUser.proStatusId,
            address: props.loggedInUser.address,
            city: props.loggedInUser.city,
            state: props.loggedInUser.state,
            postalCode: props.loggedInUser.postalCode,
            country: props.loggedInUser.country,

            onboardingComplete: props.loggedInUser.onboardingComplete,
            profileBannerUrl: props.loggedInUser.profileBannerUrl,

            quickStart: props.loggedInUser.quickStart
        };
        userDetails.imageUrl = document.location.protocol + '//' + document.location.host + '/images/avatar.png';

        let postUserUrl = "api/Account/PostUser";
        let user = await postRequest(postUserUrl, userDetails);

        await this.props.setLoggedInUser(user);
    }

    searchOptions(searchInput) {
        const dropdownOptions = [
            {
                label: `Most Endorsed Products`,
                path: `/Browse?search=${searchInput}`,
            },
            {
                label: `Lockers (Gear Lists)`,
                path: `/SearchResults/${searchInput}?tab=locker&showBrandNav=true`,
            },
            {
                label: `Related Social Posts and Videos`,
                path: `/SearchResults/${searchInput}?tab=posts&showBrandNav=true`,
            },
            {
                label: `Relevant Creators`,
                path: `/SearchResults/${searchInput}?tab=people&showBrandNav=true`,
            },
        ];
        this.props.setDropdownOptions(dropdownOptions);
    }

    searchInputHandle = (event) => {
        this.props.toggleShowDropdown(true)
        this.setState({ searchValue: event.target.value, redirectValue: event.target.value });
        this.props.updateSearchInput(event.target.value);

        this.searchOptions(event.target.value);
    }

    searchInputSubmit = (e) => {
        if (e.keyCode === 13 && this.state.redirectValue.length > 1) {
            this.setState({ redirectSearch: true });
            document.getElementById("redirectLink").click();
            this.setState({ redirectSearch: false });
        }
    }

    toggleModal = () => {
        this.setState({ showSharePostModal: !this.state.showSharePostModal });
    }

    handleDropdownOptionClick(option) {
        this.props.toggleShowDropdown(false);
        this.props.history.push(option.path);
    }

    authenticatedView(user, profilePath, logoutPath) {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        return (
            <Fragment>
                <div className="nav-block test" id="main_div" style={{ display: "inline-flex", flexDirection: "row", justifyContent: "space-between", width: this.props.loggedInUser?.proStatusId !== 1 ? "20px" : "fit-content", position: "relative", top: isSmallDevice && !this.props.creatorCentricView ? "-1px" : "1px" }} >

                    {this.props?.loggedInUser?.proStatusId === 1 ? (
                        <>
                            <div className={`${isSmallDevice ? "ml-1" : "ml-3 mr-2"}`} style={{ display: this.props.creatorCentricView ? "none" : "", textAlign: "right" }}>
                                <Link to="/CreatorDashboard" className="btn btn-primary" style={{ fontSize: this.props.screenWidth < 550 ? "10px" : "", borderRadius: "30px", padding: "5px 10px" }}>Creator Dashboard</Link>
                            </div>
                            <NavItem className="dropdown product-dropdown" style={{ display: "none" }}>
                                <NavLink tag={Link} className="btn dropdown-toggle p-0 d-flex justify-content-center align-items-center"
                                    type="button"
                                    to="/"
                                    id="product-dropdown"
                                    data-toggle="dropdown"
                                >
                                    <PlusIconNav height="8" width="8" />

                                </NavLink>

                                <ul className={`dropdown-menu py-0`} aria-labelledby="dropdownMenuButton">
                                    {this.mapAddIconDropdown()}
                                </ul>

                            </NavItem>
                        </>
                    ) : (

                        /* Ensures that the navbar logo stays centered on mobile for non-creators by adding an empty span with the same width as a Creators + button */
                    //    <span style={["lg", "xl"].includes(this.props?.deviceSize) ? { width: "200px", height: "20px" } : {}}></span>
                            <div style={{ width: "68px", height: "50px" }}></div>
                    )
                    }
                    {this.renderLoginDropdown(logoutPath)}
                </div>

            </Fragment>
        )
    }

    anonymousView(registerPath, loginPath) {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        return (
            <div className={`${isSmallDevice ? "" : "ml-3 mr-1"}`} style={{ textAlign: "right", position: "relative", top: isSmallDevice ? "-2px" : "" }}>
                <a href="/Identity/Account/Login" className="btn btn-primary" style={{ borderRadius: "30px", padding: "5px 10px" }}>Login</a>
            </div>
        )
    }

    render() {
        const { isAuthenticated } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = {
                pathname: `${ApplicationPaths.LogOut}`, state: { local: true }
            };
            return this.authenticatedView(this.props.loggedInUser, profilePath, logoutPath);
        }
    }

}

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer };
    return result;
}

export default connect(mapStateToProps, { toggleFeedLayout, toggleAddProductModal, toggleCreateLockerModal, toggleShareModal, toggleSharePostModal, toggleShareBlogModal, toggleShareDeepLinkModal, toggleAddProductOrDeepLinkModal })(withRouter(LoginMenu));