import React from "react";
import DashboardTile from "components/Dashboard/DashboardTile";
import AccountBanner from "components/AccountBanner";
import _ from "lodash";

class AdminDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    adminLinks = _.sortBy([
        { link: "/Admin/BrandApplications", title: "Merchant Partner applications", icon: <span></span> },
        { link: "/Admin/CreatorApplications", title: "Creator applications", icon: <span></span> },
        { link: "/Admin/Brands", title: "Merchant Partners", icon: <span></span> },
        { link: "/Admin/AffiliateNetworks", title: "Affiliate Networks", icon: <span></span> },
        { link: "/Admin/Sectors", title: "Sectors", icon: <span></span> },
        { link: "/Admin/CommissionOverrides", title: "Commission Overrides", icon: <span></span> },
        { link: "/Admin/ManageCommissions", title: "Manage Commissions", icon: <span></span> },
        { link: "/Admin/Users", title: "Users", icon: <span></span> },
        { link: "/Admin/PaymentRuns", title: "Payment Runs", icon: <span></span> },
        { link: "/Admin/ItOperations", title: "IT Operations", icon: <span></span> },
        { link: "/Admin/Category", title: "Category", icon: <span></span> },
        { link: "/Admin/Analytics/Analytics", title: "Analytics", icon: <span></span> },
        { link: "/Admin/ManageBrands", title: "Manage Brands", icon: <span></span> },
        { link: "/Admin/CurrentDeals", title: "Current Deals", icon: <span></span> },
        { link: "/Admin/Featured", title: "Featured", icon: <span></span> },
        { link: "/Admin/CreatorDashboard", title: "Creator Dashboard", icon: <span></span> },
    ], x => x.title);

    breadCrumbList = [
       { link: '/Admin', text: 'Admin' }
    ]

    componentDidMount = async () => {
        this.props.setShowTopNav(true);
    }

    displayAdminLinks = () => {
        return this.adminLinks.map((adminLink, i) => {
            return (
                <div className="col-6 col-lg-4 mb-4" key={i}>
                    <DashboardTile link={adminLink.link} icon={adminLink.icon} title={adminLink.title} />
                </div>
                )
        })
    }

    render() {
        return (
            <section>
                <AccountBanner breadCrumbList={this.breadCrumbList}>
                    <h1 className="mb-4">Admin</h1>
                    <p className="mb-0">Manage users and Merchant Partners</p>
                </AccountBanner>
                <section className="gray-bg full-height">
                    <div className="container">
                        <div className="row py-5 mt-0">
                            {this.displayAdminLinks()}
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default AdminDashboard;