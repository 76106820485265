import React from "react";
import { withRouter } from 'react-router-dom';
import AccountBanner from "../../components/AccountBanner"
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import InformationIcon from "components/icons/InformationIcon";
import { Popover, UncontrolledPopover, PopoverBody } from "reactstrap";
import Select from 'react-select';
import { debounce } from "throttle-debounce";
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import MerchantShareBlock from "components/Share/MerchantShareBlock";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getMerchantShareLink } from "../../sharedUtils/shareLinkPrefixes"
import ShareIosIcon from "../../components/icons/ShareIosIcon";
import { Helmet } from 'react-helmet';
import BookmarkIcon from "components/icons/BookmarkIcon";
import { filter } from "lodash";

class Brands extends React.Component {

    constructor(props) {
        super(props)

        this.selectOptions = {
            name: {
                value: 1,
                label: "Name",
            },
            recentlyAdded: {
                value: 2,
                label: "Recently Added"
            },
            commission: {
                value: 3,
                label: "Estimated Commission"
            },
            favorites: {
                value: 4,
                label: "Favorites"
            },
        }

        this.state = {
            modalOpen: false,
            shareLink: "",
            filter: "",
            filteredPartners: [],
            partners: [],
            loading: true,
            sectors: [],
            partnerSectors: [],
            selectedSectors: [],
            selectOptions: {
                options: [
                    this.selectOptions.name,
                    this.selectOptions.recentlyAdded,
                    this.selectOptions.commission,
                    this.selectOptions.favorites,
                ],
                defaultValue: this.selectOptions.name,
                currentValue: this.selectOptions.name,
            },
            favorites: false,
        }
    }

    breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/Brands', text: 'Merchant Partners', active: true }];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }

        if (!this.props.creatorDashboard) {
            window.scrollTo(scrollOptions);
        }

        await Promise.all([
            this.getPartners(),
            this.getPartnerSectors(),
            this.getSectors(),
        ]);

        await this.filterPartners();

        this.setState({ loading: false })
    }

    getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors');
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        this.setState({ sectors });
    }

    getPartners = async () => {
        let partnersUrl = '/api/Merchant/GetCommissionEstimate?showAll=false&getMaxByMerchant=true';
        let partners = await getRequest(partnersUrl);

        if (!!(partners)) {
            partners = partners.map(partner => ({
                ...partner,
                date: partner.integratedDate.split('T')[0]
            }));
        }
        this.setState({ partners: partners, filteredPartners: partners });
    }

    getPartnerSectors = async () => {
        let partnerSectorsUrl = '/api/Sector/GetAllMerchantSectors';
        let partnerSectors = await getRequest(partnerSectorsUrl);
        this.setState({ partnerSectors });
    }

    // Original Searchbar method for onChange
    onInputChange = async (e) => {
        let filter = e.target.value;

        await this.setState({ filter: filter });

        this.filterPartners(true);
    };

    bounce = debounce(300, () => {
        this.filterPartners(true);
    });

    // Taking the place of onInputChange above this method to prove debouncing concept for searchbar
    debounceFunc = (e) => {
        let filter = e.target.value;
        this.setState({ filter: filter });
        this.bounce();
    }

    renderShareOverlay = (shareLink) => {
        return (<span className="ml-2 position-absolute" onClick={(e) => {
            e.preventDefault();
            this.props.toggleShareModal(shareLink);
        }} style={{ cursor: "pointer", backgroundColor: "#f8f9fa", borderRadius: "999px", padding: "5px", bottom: "-2px" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    displayShareModal = () => {
        let shareProps = {
            shareCode: this.state.shareLink,
            title: "Merchant share",
            customStyle: { maxWidth: "100%", backgroundColor: "white" },
            shareBlockStyle: { border: "none", backgroundColor: "transparent" },
            loggedInUser: this.props.loggedInUser,
            type: "merchant",
        }
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProductShareModal}>
                <ShareBlock {...shareProps}>
                    <MerchantShareBlock
                        {...shareProps}
                    />
                </ShareBlock>
            </Modal>
        )
    }

    handleUserFavorite = async (partner) => {
        const updatedPartners = this.state.partners.map(p => {
            if (p.id === partner.id) {
                return { ...p, userFavorite: !p.userFavorite };
            }
            return p;
        });

        const updatedFilteredPartners = this.state.filteredPartners.map(p => {
            if (p.id === partner.id) {
                return { ...p, userFavorite: !p.userFavorite };
            }
            return p;
        });

        this.setState({
            partners: updatedPartners,
            filteredPartners: updatedFilteredPartners,
        });

        const userMerchant = {
            merchantId: partner.id,
            userId: this.props.loggedInUser.id,
        };

        if (partner.userFavorite) {
            let url = '/api/Account/DeleteUserFavoriteBrand';
            await postRequest(url, userMerchant);
        } else {
            let url = '/api/Account/CreateUserFavoriteBrand';
            await postRequest(url, userMerchant);
        }
    }

    toggleProductShareModal = async (urlFragment) => {
        let showModal = !this.state.modalOpen;
        let shareLink = await getMerchantShareLink(urlFragment);

        if (showModal) {
            this.setState({ modalOpen: showModal, shareLink });
        }
        else {
            this.setState({ modalOpen: showModal, shareLink: "" });
        }
    }

    displayTableRows = () => {
        return this.state.filteredPartners?.map((partner, i) => {
            let featuredCount = this.state.favorites ? 0 : this.state.filteredPartners?.filter(partner => !!(partner.ordinal)).length;

            let redirectToMerchatUrl = `/mlink/${partner.id}?userId=${this.props.loggedInUser.id}`;
            const today = new Date();
            const thirtyDaysPriorToToday = new Date().setDate(today.getDate() - 30);
            const newLabel = new Date(partner?.date).getTime() > thirtyDaysPriorToToday && !(partner.ordinal) ?
                //<div className="brands-new-container">
                <div className="d-flex flex-column justify-content-center align-items-center ml-3 brands-new">
                    <div className="brands-new-text">
                        New
                    </div>
                </div>
                //</div >    
                :
                <div></div>;
            const featuredLabel = !!(partner.ordinal) && !this.state.favorites ?
                //<div className="brands-new-container">
                <div className="d-flex flex-column justify-content-center align-items-center ml-3 brands-new" style={{ background: "none" }} >
                    <div className="brands-new-text" style={{ left: "5px", color: "black" }}>
                        Featured
                    </div>
                </div>
                //</div >    
                :
                <div></div>;
            const renderBookmarkOverlay = () => {
                return (
                    <span className="brands-page-bookmark-overlay position-absolute" onClick={() => {this.handleUserFavorite(partner)}} style={{ cursor: "pointer", }}>
                        <BookmarkIcon height="16" width="16" fill={partner.userFavorite ? "#e55934" : "none"} />
                    </span>);
            }
            const shareBtn = <div
                className="ml-auto new-button"
                style={{ padding: this.props.deviceSize === "sm" ? "1px 10px" : "", minWidth: this.props.deviceSize === "sm" ? "85px" : "", cursor: "pointer", lineHeight: this.props.deviceSize === "sm" ? "1.75rem" : "", paddingTop: this.props.deviceSize === "sm" ? "1px" : "3px", paddingBottom: this.props.deviceSize === "sm" ? "1px" : "3px" }}
                onClick={() => this.toggleProductShareModal(redirectToMerchatUrl)}
            >Get Link</div>;

            let commissionPercentage = partner.commission * 100;
            if (commissionPercentage > 0) {
                let roundedCommissionPercentage = commissionPercentage.toFixed(2);
                if (roundedCommissionPercentage.toString().endsWith(".00")) { roundedCommissionPercentage = roundedCommissionPercentage.toString().slice(0, -3) };



                return (
                    <tr key={i} style={{ borderBottom: featuredCount === 0 ? "" : i === featuredCount - 1 ? "6px solid lightgray" : "" }}>
                        <td>
                            <div className="d-flex align-items-center brands-new-wrapper" >
                                <a href={redirectToMerchatUrl} target={partner.merchantName}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ objectFit: "contain" }} alt="Brand logo" src={partner.merchantLogoUrl} />
                                        <h6>{partner.merchantName}</h6>
                                    </div>
                                </a>
                                {newLabel}
                                {featuredLabel}
                                {shareBtn}
                                {renderBookmarkOverlay()}
                            </div>
                        </td>
                        <td>
                            <span>{roundedCommissionPercentage}%</span>
                        </td>
                    </tr>);
            }

        });
    }

    displayTableHeader = () => {
        let headers = ["Name", "Estimated Commission"];
        return headers.map((header, i) => {
            return (
                <th key={i}>
                    <strong>{header}
                        <small className="d-inline-block ml-1 ml-md-1" style={{ position: "relative", top: "-2px" }}>
                            <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id={header === "Estimated Commission" ? "infoIcon" : "nameInfoIcon2"} ><InformationIcon /></button>
                        </small>
                        <UncontrolledPopover
                            placement="bottom"
                            target={header === "Estimated Commission" ? "infoIcon" : "nameInfoIcon2"}
                            trigger="legacy">
                            <PopoverBody>
                                {header === "Estimated Commission" ? "Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase." :
                                    "You earn commissions on your own purchases, just by using one of the links to our partners below!"}
                            </PopoverBody>
                        </UncontrolledPopover>
                    </strong>
                </th>
            )
        })
    }

    handleSelect = async (e) => {
        let selectedSectors = e.map((sector) => { return sector.value });
        await this.setState({ selectedSectors });
        this.filterPartners(true);
    }

    handleSortSelect = async (e, filteredPartners = []) => {
        let selectOptions = this.state.selectOptions;

        if (!!(e)) {
            selectOptions.currentValue = e;
        } else {
            e = selectOptions.currentValue;
        }

        let favorites = e.value === 4;

        if (filteredPartners.length === 0)
            filteredPartners = [...this.state.filteredPartners];

        let finalFilteredPartners = [];

        if (e.value === 1) {
            let partnersWithOrdinal = filteredPartners.filter(partner => partner.ordinal !== undefined && partner.ordinal !== null);
            let partnersWithoutOrdinal = filteredPartners.filter(partner => partner.ordinal === undefined || partner.ordinal === null);
            partnersWithoutOrdinal.sort((a, b) => a.merchantName.toLowerCase() < b.merchantName.toLowerCase() ? -1 : 1);
            finalFilteredPartners = [...partnersWithOrdinal, ...partnersWithoutOrdinal];
        }

        if (e.value === 2) {
            let partnersWithOrdinal = filteredPartners.filter(partner => partner.ordinal !== undefined && partner.ordinal !== null);
            let partnersWithoutOrdinal = filteredPartners.filter(partner => partner.ordinal === undefined || partner.ordinal === null);
            partnersWithoutOrdinal.sort((a, b) => a.date > b.date ? -1 : 1);
            finalFilteredPartners = [...partnersWithOrdinal, ...partnersWithoutOrdinal];
        }

        if (e.value === 3) {
            let partnersWithOrdinal = filteredPartners.filter(partner => partner.ordinal !== undefined && partner.ordinal !== null);
            let partnersWithoutOrdinal = filteredPartners.filter(partner => partner.ordinal === undefined || partner.ordinal === null);
            partnersWithoutOrdinal.sort((a, b) => a.commission > b.commission ? -1 : 1);
            finalFilteredPartners = [...partnersWithOrdinal, ...partnersWithoutOrdinal];
        }

        if (e.value === 4) {
            let favoritesPartners = filteredPartners.filter(partner => partner.userFavorite === true);
            let nonFavoritePartners = filteredPartners.filter(partner => partner.userFavorite === false);

            favoritesPartners.sort((a, b) => a.merchantName.toLowerCase() < b.merchantName.toLowerCase() ? -1 : 1);
            nonFavoritePartners.sort((a, b) => a.merchantName.toLowerCase() < b.merchantName.toLowerCase() ? -1 : 1);

            finalFilteredPartners = [...favoritesPartners, ...nonFavoritePartners];
        }

        this.setState({ filteredPartners: finalFilteredPartners, selectOptions, favorites });
    };


    filterPartners = async (sort = false) => {
        const filter = this.state.filter;
        let filteredPartners;;

        if (filter.trim().length > 0) {
            const url = `api/Merchant/GetFilteredMerchants?merchantString=${filter.trim()}`;
            let filteredArray = await getRequest(url);
            filteredPartners = this.state.partners.filter(p => filteredArray.find(fa => fa.id === p.id));
        } else {
            filteredPartners = [...this.state.partners];
        }

        let partnersWithOrdinal = filteredPartners.filter(partner => partner.ordinal !== undefined && partner.ordinal !== null);
        let partnersWithoutOrdinal = filteredPartners.filter(partner => partner.ordinal === undefined || partner.ordinal === null);

        let sortedPartnersWithOrdinal = partnersWithOrdinal.sort((a, b) => a.ordinal - b.ordinal);
        let finalFilteredPartners = [...sortedPartnersWithOrdinal, ...partnersWithoutOrdinal];

        if (this.state.selectedSectors.length > 0) {
            let partnersFilteredBySectors = this.state.partnerSectors?.filter(partnerSector => this.state.selectedSectors.indexOf(partnerSector.sectorId) !== -1)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((filteredPartnerSector) => { return filteredPartnerSector.merchantId });
            finalFilteredPartners = finalFilteredPartners.filter(partner => partnersFilteredBySectors.indexOf(partner.id) !== -1);

            this.setState({ filteredPartners: finalFilteredPartners });
        } else {
            this.setState({ filteredPartners: finalFilteredPartners });
        }

        if (sort) {
            this.handleSortSelect(null, finalFilteredPartners);
        }
    };

    saveUser = async (loggedInUser) => {
        let serializedUser = {
            name: loggedInUser.name,
            firstName: loggedInUser.firstName,
            lastName: loggedInUser.lastName,
            bio: loggedInUser.bio,
            tagLine: loggedInUser.tagLine,
            website: loggedInUser.website,
            imageUrl: loggedInUser.imageUrl,
            userName: loggedInUser.userName,
            email: loggedInUser.email,

            phoneNumber: loggedInUser.phoneNumber,
            proStatusId: loggedInUser.proStatusId,
            address: loggedInUser.address,
            city: loggedInUser.city,
            state: loggedInUser.state,
            postalCode: loggedInUser.postalCode,
            country: loggedInUser.country,

            onboardingComplete: loggedInUser.onboardingComplete,
            profileBannerUrl: loggedInUser.profileBannerUrl,
            quickStart: loggedInUser.quickStart
        };

        let userUrl = '/api/Account/PostUser';
        await postRequest(userUrl, serializedUser);
    }

    render() {
        let smallSizes = ["sm", "md"];
        let { deviceSize } = this.props;

        if (this.state.loading) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>RockPorch - Merchant Partners</title>
                    </Helmet>
                </>
            )
        }
        return (
            <>
                <Helmet>
                    <title>RockPorch - Merchant Partners</title>
                </Helmet>
                {this.displayShareModal()}
                <section style={{ display: this.props.creatorDashboard ? "none" : "" }}>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Merchant Partners</h1>
                        <p className="mb-0">View Merchant Partners and commission rates</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5 px-0 px-md-3">

                        <div className="search-interest pt-4 pb-0 mx-auto">
                            <div className="search-block">
                                <div className="row mx-0">
                                    <div className="col-12 col-md-8">
                                        <div className="form-group position-relative mr-5">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search for brands & retailers..." value={this.state.filter} onChange={(e) => this.debounceFunc(e)} />
                                            <input type="submit" className="position-absolute" />
                                            <small className="d-inline-block ml-1 ml-md-1" style={{ position: "absolute", top: "10px", right: "10px" }}>
                                                <button style={{ outline: "none", border: 0, padding: 0, backgroundColor: "rgba(219,219,219,0.1)" }} id="merchantInfoButton"><InformationIcon /></button>
                                            </small>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-1 position-relative float-right">
                                        <Select
                                            isMulti={true}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Filter interests..."
                                            blurInputOnSelect={true}
                                            captureMenuScroll={true}
                                            onChange={(e) => { this.handleSelect(e) }}
                                            options={this.state.sectors?.map((sector) => {
                                                return { value: sector.sectorId, label: sector.name };
                                            })} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="search-interest pb-4 mx-auto">
                            <div className="search-block">
                                <div className="row mx-0">
                                    <div className="col-12 col-md-8">

                                    </div>
                                    <div className="col-12 col-md-4 mt-1 position-relative float-right">
                                        <Select
                                            isMulti={false}
                                            isClearable={false}
                                            isSearchable={false}
                                            placeholder="Sort by..."
                                            blurInputOnSelect={true}
                                            captureMenuScroll={true}
                                            onChange={(e) => { this.handleSortSelect(e) }}
                                            options={this.state.selectOptions.options} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-0 my-0">

                            <div className="table-responsive product-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {this.displayTableHeader()}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.displayTableRows()}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleProductPageModal, toggleShareModal })(Brands);